// @flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import AntCard from 'antd/lib/card';
import { Element, scroller } from 'react-scroll';

import type { OrderContractorType, Vehicle } from '../../../../lib/types';
import { Section } from '../../../../components/layout/index';
import { Card } from '../../../../components';
import Grid from '../../../../components/layout/Grid';
import SectionTitle from '../SectionTitle';
import { Link } from '@reach/router';
import { GridItem } from '../../../../components/layout';
import { Table } from '../../../../components/ui';
import { orderContractorTypes } from '../../../../lib/enum';
import { maintenanceApi, vehicleApi } from '../../../../lib/api';
import { notification } from 'antd';
import { toLocalString, showWorkEngineHours } from '../../../../lib/helpers';

const { Field } = Card;

const SectionContent = styled.div`
  padding: 16px;
`;

const columns = [
  {
    title: '№ карточки работ',
    dataIndex: 'id',
    render: (id: number) => {
      const url = `/maintenances/journal/read/${id}`;
      return <Link to={url}>{id}</Link>;
    }
  },
  {
    title: 'Планируемая дата проведения работ',
    dataIndex: 'maintenancePlannedDate',
    render: maintenancePlannedDate =>
      maintenancePlannedDate
        ? moment(maintenancePlannedDate).format('DD.MM.YYYY')
        : '-'
  },
  {
    title: 'Способ выполнения',
    dataIndex: 'orderContractorType',
    render: (type: OrderContractorType) => orderContractorTypes[type]
  },
  {
    title: 'Итого, руб',
    dataIndex: 'totalSum'
  }
];

const ACard = props => (
  <AntCard {...props} headStyle={{ background: '#f0f4f8' }} />
);

const formatDate = value => {
  return value ? moment(value).format('DD.MM.YYYY') : value;
};

type Props = {
  vehicle: Vehicle,
  editable: boolean,
  canHandleMaintenanceServices: boolean,
  anchor: ?string
};

const MaintenanceTab = ({ vehicle, anchor }: Props) => {
  const [maintenance, setMaintenance] = useState([]);
  const [maintenanceInfo, setMaintenanceInfo] = useState({});
  const [maintenanceCosts, setMaintenanceCosts] = useState(0);

  useEffect(() => {
    if (anchor) {
      scroller.scrollTo(anchor, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  });
  useEffect(() => {
    try {
      const fetch = async () => {
        const { data, maintenanceCosts } = await maintenanceApi.getForVehicle(
          vehicle.id
        );
        setMaintenance(data);
        setMaintenanceCosts(maintenanceCosts);
        const maintenanceInfo = await vehicleApi.getMaintenanceInfoForVehicle(
          vehicle.id
        );
        setMaintenanceInfo(maintenanceInfo);
      };
      fetch().then();
    } catch (e) {
      notification.error({ message: e.message });
    }
  }, [vehicle.id]);
  return (
    <>
      <Element name="planed-maintenance">
        <Section>
          <SectionTitle divider>Техническое обслуживание</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <ACard title="ТО-1">
                <Grid gutter="16px">
                  <GridItem>
                    <Field label="Дата последнего технического обслуживания">
                      {formatDate(maintenanceInfo.lastTo1Date) || ''}
                    </Field>
                  </GridItem>
                  <GridItem>
                    {showWorkEngineHours(vehicle) ? (
                      <Field label="Моточасы при прохождении ТО">
                        {toLocalString(maintenanceInfo.lastTo1WorkEngineHours) || ''}
                      </Field>
                    ) : (
                        <Field label="Пробег при прохождении ТО">
                          {toLocalString(maintenanceInfo.lastTo1Kilometrage) || ''}
                        </Field>
                      )}
                  </GridItem>
                  <GridItem>
                    <Field label="Дата следующего технического обслуживания">
                      {formatDate(maintenanceInfo.nextTo1PlannedDate) || ''}
                    </Field>
                  </GridItem>
                  <GridItem>
                    {showWorkEngineHours(vehicle) ? (
                      <Field label="Моточасы при прохождении следующего ТО">
                        {toLocalString(maintenanceInfo.nextTo1PlannedWorkEngineHours) || ''}
                      </Field>
                    ) : (
                        <Field label="Пробег при прохождении следующего ТО">
                          {toLocalString(
                            maintenanceInfo.nextTo1PlannedKilometrage
                          ) || ''}
                        </Field>
                      )}
                  </GridItem>
                </Grid>
              </ACard>
              <ACard title="ТО-2">
                <Grid gutter="16px">
                  <GridItem>
                    <Field label="Дата последнего технического обслуживания">
                      {formatDate(maintenanceInfo.lastTo2Date) || ''}
                    </Field>
                  </GridItem>
                  <GridItem>
                    {showWorkEngineHours(vehicle) ? (
                      <Field label="Моточасы при прохождении ТО">
                        {toLocalString(maintenanceInfo.lastTo2WorkEngineHours) || ''}
                      </Field>
                    ) : (
                        <Field label="Пробег при прохождении ТО">
                          {toLocalString(maintenanceInfo.lastTo2Kilometrage) || ''}
                        </Field>
                      )}
                  </GridItem>
                  <GridItem>
                    <Field label="Дата следующего технического обслуживания">
                      {formatDate(maintenanceInfo.nextTo2PlannedDate) || ''}
                    </Field>
                  </GridItem>
                  <GridItem>
                    {showWorkEngineHours(vehicle) ? (
                      <Field label="Моточасы при прохождении следующего ТО">
                        {toLocalString(maintenanceInfo.nextTo2PlannedWorkEngineHours) || ''}
                      </Field>
                    ) : (
                        <Field label="Пробег при прохождении следующего ТО">
                          {toLocalString(
                            maintenanceInfo.nextTo2PlannedKilometrage
                          ) || ''}
                        </Field>
                      )}
                  </GridItem>
                </Grid>
              </ACard>
            </Grid>
          </SectionContent>
        </Section>
      </Element>
      <Element name="maintenance">
        <Section>
          <SectionTitle divider>История ремонтов</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              {maintenanceCosts ? (
                <GridItem>
                  <Field label="Итого потрачено на ремонт, руб">
                    {maintenanceCosts} ₽
                  </Field>
                </GridItem>
              ) : null}
            </Grid>
            <Table data={maintenance} columns={columns} />
          </SectionContent>
        </Section>
      </Element>
    </>
  );
};

MaintenanceTab.defaultProps = {
  washings: []
};

export default MaintenanceTab;
