//@flow
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import { CloseOutlined } from '@ant-design/icons';
import { FormikProps } from 'formik';
import moment from 'moment';
import styled from 'styled-components';

import { multipliedBy, toLocalStringRu, formatRub } from '../../../lib/helpers';
import type { BioTreatmentFacilityTalon, SideServiceTariffType } from '../../../lib/types';
import { sideServiceTypeEnum } from '../../../lib/enum';
import { sideServiceTariffApi } from '../../../lib/api';

import { InputNumber } from '../../../components/inputs';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import { Form, Card } from '../../../components';
import { Grid } from '../../../components/layout';
import { GridItem } from '../../../components/layout/Grid';
import { LicensePlateInput } from '../../../components/inputs/masked-inputs';
import { notification } from 'antd';

const { Field } = Card;

export const CloseIcon = styled(CloseOutlined)`
  cursor: pointer;
  font-size: 5px;
  background: #d9d9d9;
  border: 1px solid white;
  position: absolute;
  margin-top: -7px;
  margin-left: -2px;
  padding: 2px;
  border-radius: 50%;
`;
const TagContainer = styled.div`
  word-break: break-word;
`;
const Tag = styled.span`
  padding: 2px 5px;
  margin: 3px 2px;
  font-size: 12px;
  background: #d9d9d9;
  display: inline-block;
`;

type Props = {
  visible: boolean,
  onCancel: Function,
  onSubmit: Function,
  onSubmitApprove: Function,
  bioTreatmentFacilityTalon: ?BioTreatmentFacilityTalon,
  canApprovingTalon: boolean,
  organizationUnitId?: number,
  sideServiceTariffType?: SideServiceTariffType,
};
export default (props: Props) => {
  const [licensePlate, setLicensePlate] = useState(null);
  const [actionSaveApprove, setActionSaveApprove] = useState(false);
  const [price, setPrice] = useState(0);
  useEffect(() => {
    props.organizationUnitId && getCurrent();
  }, [props.organizationUnitId]);
  const getCurrent = async () => {
    const { organizationUnitId, sideServiceTariffType } = props;
    try {
      const sideServiceTariff = await sideServiceTariffApi.current({
        sideServiceType: sideServiceTypeEnum.wasteDump,
        sideServiceTariffType,
        organizationUnitId,
      });
      if (sideServiceTariff?.price) {
        setPrice(sideServiceTariff.price);
      } else {
        setPrice(0);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось получить стоимость услуги',
      });
    }
  };
  const onSubmit = (values) => {
    const { onSubmit, onSubmitApprove } = props;
    if (actionSaveApprove) {
      onSubmitApprove(values);
    } else {
      onSubmit(values);
    }
    setActionSaveApprove(false);
  };
  const {
    visible,
    onCancel,
    canApprovingTalon,
    bioTreatmentFacilityTalon = {},
  } = props;
  return (
    <Modal
      destroyOnClose
      width={800}
      title="Форма внесения талона"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        canApprovingTalon ? (
          <Button
            key="submitSave"
            type="primary"
            form="modal-form"
            htmlType="submit"
            onClick={() => {
              setActionSaveApprove(true);
            }}
          >
            Сохранить и утвердить
          </Button>
        ) : null,
        <Button key="submit" type="primary" form="modal-form" htmlType="submit">
          Сохранить
        </Button>,
      ]}
    >
      <Form initialValues={bioTreatmentFacilityTalon} onSubmit={onSubmit}>
        {(FormField, formikProps: FormikProps) => {
          const { setFieldValue, handleSubmit, values } = formikProps;
          return (
            <form onSubmit={handleSubmit} id="modal-form">
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    label="Выдано талонов"
                    name="issuedCoupons"
                    required
                  >
                    {({ name, value }) => (
                      <InputNumber
                        fullWidth
                        placeholder="Введите кол-во"
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                          setFieldValue(
                            'sum',
                            multipliedBy(
                              multipliedBy(
                                price,
                                values?.bioTreatmentFacilityVolume?.volume ?? 0
                              ),
                              value
                            )
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem />
                <GridItem>
                  <Field label="Объем, м3">
                    {toLocalStringRu(
                      values?.bioTreatmentFacilityVolume?.volume ?? 0
                    )}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Сумма">{formatRub(values?.sum ?? 0)}</Field>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
