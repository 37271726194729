import React, { Component, type Node } from 'react';
import notification from 'antd/lib/notification';
import styled, { css } from 'styled-components';
import qs from 'query-string';

import Pagination from './Pagination';
import Icon from './Icon';
import type { FetchListParams } from '../../lib/api';
import { Table as AntTable } from '../ant/table/Table';

const StyledPagination = styled(Pagination)`
  padding: 10px;
`;
const HeaderCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.sorter &&
    css`
      cursor: pointer;
    `};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `};
`;

export const TableHeader = styled.div`
  white-space: break-spaces;
  width: ${(props) => props.width || '180px'};
`;

export type Column = {
  /**
   * Название столбца
   */
  title?: Node,
  /**
   * Ключ из данных
   */
  dataIndex?: string,
  /**
   * Ключ, по которому нужно сортировать
   */
  sorterKey?: string,
  /**
   * Сортировка по столбцу
   */
  sorter?: boolean,
  /**
   * Ширина столбца
   */
  width?: string | number,
  // Переносить слова, если не помещается в колонку
  breakByWidth?: boolean,
  /**
   * Фиксация стоблца по левому или правому краю
   */
  fixed?: 'left' | 'right' | boolean,
  /**
   * Отображение содержимого ячейки
   * @param value Значение, соответствующее ключу dataIndex
   * @param record Вся запись
   */
  render?: (value: any, record: any, index: number) =>?Node,
  /**
   * Отключает propagation на колонке
   */
  stopPropagation?: boolean,
  onCell?: (record: Object, index: number) => Object,
};

export type sortOrder = 'ascend' | 'descend';

type Props = {
  /**
   * Данные таблицы
   */
  data: any[],
  /**
   * Колонки
   */
  columns: Column[],
  /**
   * Назначение действий для строки таблицы
   * Например,
   * onRow={record => ({
   *   onClick: () => handleRowClick(record.id)
   * })}
   */
  onRow?: (record: any) => any,
  /**
   * Назначение действий для ячейки таблицы
   * @param record Запись
   * Например,
   * onCell={record => ({
   *   onClick: () => handleRowClick(record.id)
   * })}
   */
  onCell?: (record: any) => any,
  /**
   * Функция, для запроса данных
   * @param page Страница
   * @param params Параметры запроса списка
   */
  fetch?: (page: number, params: FetchListParams<any>) => Promise<void>,
  /**
   * Объект пагинации
   */
  pagination?: {
    // Страница
    page: number,
    // Общее число записей
    totalCount: number,
    // Кол-во записей на странице
    pageSize: number,
    // Объект для роутинга страниц
    location: Location & { state: { page: number } },
    // Не изменять страницу в queryParams
    disableSetQueryParamPage?: boolean,
  },
  /**
   * Локализация таблицы
   */
  locale?: {
    filterTitle: string,
    filterConfirm: string,
    filterReset: string,
    emptyText: string,
  },
  /**
   * key нужный для React, присваиваемый каждой строке таблицы
   */
  rowKey?: string | ((record: any) => string),
  /**
   * Раскрытие строки таблицы
   * @param expanded
   * @param record
   */
  onExpand?: (expanded: any, record: any) => any,
  /**
   * Подгружать данные после маунта
   *
   * Временный пропс для обратной совместимости
   * TODO: убрать после рефакторинга списков
   */
  fetchOnMount?: boolean,
  defaultOrderBy?: string,
  defaultOrderDirection?: sortOrder,
};

type State = {
  orderBy: string,
  order: sortOrder,
  /**
   * Маппинг колонок для которых доступна сортировка и их ключей для сортировки
   * У анта глюк с сортировкой - сортирует только в одну сторону
   * Для сортировки в другую сторону приходится использовать двойной клик
   */
  sorterMap: {
    [key: string]: string,
  },
};

export default class Table extends Component<Props, State> {
  state = {
    sorterMap: {},
    orderBy: this.props.defaultOrderBy ? this.props.defaultOrderBy : '',
    order: this.props.defaultOrderDirection
      ? this.props.defaultOrderDirection
      : 'descend',
  };

  async componentDidMount() {
    try {
      const { columns, fetchOnMount, disableSetQueryParamPage } = this.props;
      if (fetchOnMount) {
        const { page } = qs.parse(window.location.search);
        await this.fetchWithPage(!disableSetQueryParamPage ? 1 : page);
      }
      /**
       * Мапим из массива колонок в объект с ключом и значением
       * равным либо dataIndex или
       * sorterKey (ключ, отправляемый на сервер для сортировки)
       */
      this.setState({
        sorterMap: columns.reduce((accum, col) => {
          return col.dataIndex
            ? {
              ...accum,
              [col.dataIndex]:
                col.sorter === true
                  ? col.sorterKey || col.dataIndex
                  : undefined,
            }
            : accum;
        }, {}),
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  /**
   * Fetch метод для пагинации
   * Пробрасывает поле и порядок сортировки,
   * чтобы они не терялись между страницами
   */
  fetchWithPage = async (page: number) => {
    const { orderBy, order } = this.state;
    await this.fetch(page, orderBy, order);
  };

  fetch = async (page: number, orderBy?: string, order?: sortOrder) => {
    if (this.props.fetch) {
      await this.props.fetch(page, {
        orderBy,
        byDescending: order === 'descend',
      });
    }
  };

  sortBy = async (field: string) => {
    const { pagination: { page } = { page: 1 } } = this.props;
    const { sorterMap, orderBy: stateOrderBy } = this.state;
    const orderBy = sorterMap[field];
    if (orderBy) {
      // сами переключаем состояние порядка
      let order = '';
      // если переключили колонку
      if (orderBy !== stateOrderBy) {
        order = 'descend';
      } else {
        order = this.state.order === 'descend' ? 'ascend' : 'descend';
      }
      this.setState({
        orderBy,
        order,
      });
      await this.fetch(page, orderBy, order);
    }
  };

  prepareColumns = () => {
    const { orderBy, order, sorterMap } = this.state;
    return this.props.columns.map((column) => ({
      ...column,
      dataIndex: column.dataIndex?.split('.'),
      render: (value, record, index) => (
        <div
          style={{
            width: column.width,
            display: 'inline-block',
            whiteSpace: column.breakByWidth ? 'normal' : 'nowrap',
          }}
        >
          {column.render ? column.render(value, record, index) : value}
        </div>
      ),
      onCell: (record, index) => {
        const onCell = column.onCell && column.onCell(record, index);
        return {
          onClick: (e) => {
            onCell && onCell.onClick(e);
            column.stopPropagation && e.stopPropagation();
          },
        };
      },
      title: () => (
        <HeaderCell
          onClick={() => column?.dataIndex && this.sortBy(column.dataIndex)}
          sorter={column.dataIndex && this.state.sorterMap[column.dataIndex]}
          width={column.width}
        >
          {column.title}
          {column.dataIndex && orderBy === sorterMap[column.dataIndex] && (
            <Icon
              type={order === 'descend' ? 'arrow-down' : 'arrow-up'}
              size={8}
            />
          )}
        </HeaderCell>
      ),
      sorter: false,
    }));
  };

  render() {
    const { data, pagination, ...atp } = this.props;
    let antdTableProps: $Rest<Props, {| data?: any, pagination?: any |}> = atp;
const { page, pageSize, totalCount, location, disableSetQueryParamPage } = pagination || {};
return (<>
  <AntTable
    pagination={false}
    scroll={{
      x: 'auto',
    }}
    {...antdTableProps}
    columns={this.prepareColumns()}
    dataSource={data}
  />
  {pagination && (
    <StyledPagination
      totalCount={parseInt(totalCount, 10)}
      pageSize={parseInt(pageSize, 10)}
      fetch={this.fetchWithPage}
      page={parseInt(page, 10)}
      location={location}
      disableSetQueryParamPage={disableSetQueryParamPage}
    />
  )}
</>);
  }
}
