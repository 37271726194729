// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';
import L, { Map as LeafletMap } from 'leaflet';
import 'leaflet-plugin-trackplayback';

import type { AppState } from '../../ducks/redux';
import { vehicleApi } from '../../lib/api';
import wialonApi from '../../lib/api/wialon';
import {
  hideSidebarBurger,
  closeSidebar,
  openSidebar,
  showSidebarBurger,
} from '../../ducks/sidebar';
import { Icon, Loading } from '../../components/ui';
import { VehicleInfo, VehiclesList } from './components';
import { geocode } from '../../lib/gis';
import type {
  MonitoringVehicle,
  MonitoringVehicleHistoryItem,
} from '../../lib/types/monitoringVehicle';
import { Wrapper, Content, MapWrapper, Sidebar, BackButton } from './styles';
import type { Vehicle } from '../../lib/types';
import { defaultPageSize, goBack } from '../../lib/helpers';
import {
  monitoringVehicleStatesEnum,
  monitoringVehicleStatusesEnum,
} from '../../lib/enum';
import type { MonitoringFilterParams } from './components/vehiclesList/elements';
import MonitoringMap from '../../components/maps/monitoringMap';
import { findTick } from './utils';

type Props = {
  hideSidebarBurger: Function,
  closeSidebar: Function,
  openSidebar: Function,
  showSidebarBurger: Function,
  location: Location & { state: { page: number } },
};

export const basePlaybackSpeed = 8;

/**
 * Состояние вкладки история
 */
export type MonitoringHistoryState = {
  // Дата начала
  startDate?: Date,
  // Дата окончания
  endDate?: Date,
  // Скорость воспроизведения
  speed: 1 | 2 | 4,
  // Статус проигрывателя
  status: 'play' | 'pause',
  // Элементы истории
  history: MonitoringVehicleHistoryItem[],
  // Завершено ли вопроизведение
  isEnded: boolean,
  // Индекс текущего элемента истории
  currentIndex: number,
  // Текущая временная метка воспроизведения
  currentTick?: number,
};

/**
 * Главное окно мониторинга
 */
export const MonitoringScreen = ({
  showSidebarBurger,
  openSidebar,
  closeSidebar,
  hideSidebarBurger,
  location,
}: Props) => {
  const [data, setData] = useState({});
  const [activeMonitoringVehicles, setActiveMonitoringVehicles] = useState<
    Map<number, MonitoringVehicle>
  >(new Map());
  // Список ТС
  const [vehicles, setVehicles] = useState<MonitoringVehicle[]>([]);
  // Список ТС для восстановления фильтрации
  const [originalVehicles, setOriginalVehicles] = useState<MonitoringVehicle[]>(
    []
  );
  // Выбранное ТС
  const [
    selectedMonitoringVehicle,
    setSelectedMonitoringVehicle,
  ] = useState<MonitoringVehicle>((undefined: any));
  const [vehicleInfoOpen, setVehicleInfoOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPaginationObj] = useState({
    page: 1,
    pageSize: defaultPageSize,
    totalCount: 0,
  });
  // Состояние истории
  const [historyState, setHistoryState] = useState<MonitoringHistoryState>({
    speed: 1,
    status: 'pause',
    startDate: moment('00:00', 'HH:mm').toDate(),
    endDate: moment('23:59', 'HH:mm').toDate(),
    history: [],
    currentIndex: 0,
    isEnded: false,
  });
  const [isHistoryOpened, setIsHistoryOpened] = useState<boolean>(false);
  const [playback, setPlayback] = useState(null);
  const mapRef = useRef<typeof LeafletMap>();

  /**
   * Функция для запроса виалоновского элемента по id
   */
  const fetchWialonItem = useCallback(async (vehicle: MonitoringVehicle) => {
    const { items } = await wialonApi().sections.Core.searchItems(
      vehicle.vehicle.wialonVehicleId
    );
    if (items) {
      const [item] = items;
      return item;
    } else {
      return [];
    }
  }, []);

  useEffect(() => {}, [vehicles]);

  useEffect(() => {
    Object.keys(data).forEach((key) => {
      const itemId = parseInt(key);
      if (activeMonitoringVehicles.has(itemId)) {
        let vehicle = activeMonitoringVehicles.get(itemId);
        if (
          Array.isArray(data[key]) &&
          data[key].length &&
          data[key][0]?.trips
        ) {
          const { y, x } = data[key][0]?.trips?.to;
          const { course } = data[key][0]?.trips;
          const direction = Number.isInteger(parseInt(course))
            ? parseInt(course)
            : 0;
          const speed = data[key][0]?.trips?.curr_speed ?? 0;
          if (y && x) {
            vehicle = {
              ...vehicle,
              direction,
              speed,
              geoPosition: {
                lat: y,
                lng: x,
              },
              // $FlowFixMe
              polyline: [...vehicle.polyline, [y, x]],
            };
            if (parseInt(selectedMonitoringVehicle?.itemId) === itemId) {
              // $FlowFixMe
              setSelectedMonitoringVehicle(vehicle);
            }
            // $FlowFixMe
            activeMonitoringVehicles.set(itemId, vehicle);
          }
        }
      }
    });
  }, [activeMonitoringVehicles, data, selectedMonitoringVehicle]);

  useEffect(() => {
    let timerId = setInterval(() => {
      wialonApi()
        .sections.Events.checkUpdates()
        .then((data) => setData(data));
    }, 2000);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    // console.log('updateUnits', Array.from(activeMonitoringVehicles.keys()));
    wialonApi().sections.Events.updateUnits(
      Array.from(activeMonitoringVehicles.keys())
    );
  }, [activeMonitoringVehicles]);

  /**
   * Запрашивает последнее сообщение по ТС
   */
  const fetchVehicleLastMessage = useCallback(
    async (vehicle: MonitoringVehicle) => {
      const wialonItem = await fetchWialonItem(vehicle);
      const { messages } = await wialonApi().sections.Messages.loadLast({
        // $FlowFixMe
        itemId: wialonItem?.id,
        lastTime: parseInt(new Date().getTime() / 1000),
        lastCount: 1,
        loadCount: 1,
        flags: 0,
        flagsMask: 0,
      });
      // $FlowFixMe
      return messages && { ...messages[0], itemId: wialonItem?.id };
    },
    [fetchWialonItem]
  );

  const setPagination = useCallback((state: any) => {
    setPaginationObj((prev) => ({ ...prev, ...state }));
  }, []);

  /**
   * Подгружает список всех ТС
   */
  const fetchVehicles = useCallback(
    async (page: number = 1) => {
      try {
        const { pageSize } = pagination;
        const { data, totalCount } = await vehicleApi.fetchVehicles({
          receiveWialonData: true,
          page,
          pageSize,
        });
        const vehicles: Vehicle[] = data.filter((v) => v.wialonVehicleId);

        const monitoringVehicles: MonitoringVehicle[] = await Promise.all(
          vehicles.map<Promise<MonitoringVehicle>>(async (vehicle, id) => {
            const baseVehicle = {
              id,
              vehicle,
              vehicleId: vehicle.id,
              sim: '+7 999 000 00 00',
              geoPosition: null,
              polyline: [],
              satellitesCount: 12,
              voltage: 11.5,
              signalLevel: 4,
              direction: 0,
              speed: 64,
              state: monitoringVehicleStatesEnum.stopped,
              status: monitoringVehicleStatusesEnum.nosignal,
              address: '',
              lastSynchronizedDate: moment().toISOString(),
              device: '',
              itemId: undefined,
            };
            const message = await fetchVehicleLastMessage(baseVehicle);
            if (message?.pos?.y && message?.pos?.x) {
              // $FlowFixMe
              baseVehicle.geoPosition = {
                // $FlowFixMe
                lat: message?.pos?.y,
                // $FlowFixMe
                lng: message?.pos?.x,
              };
            }
            baseVehicle.satellitesCount = message?.pos?.sc;
            baseVehicle.direction = message?.pos?.c;
            if (message?.itemId) {
              baseVehicle.itemId = message.itemId;
            }
            return baseVehicle;
          })
        );
        setVehicles(monitoringVehicles);
        setOriginalVehicles(monitoringVehicles);
        setPagination({
          page,
          totalCount,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [fetchVehicleLastMessage, pagination, setPagination]
  );

  /**
   * Обработка нажатия на кнопку назад
   */
  const handleBackClick = useCallback(() => {
    goBack('/');
    showSidebarBurger();
    openSidebar();
  }, [showSidebarBurger, openSidebar]);

  /**
   * Функция изменения состояния истории
   * Принимает только callback в качестве аргумента
   * которого выступает предыдущее состояние,
   * а возвращаемым значением кусок данных стейта
   */
  const handleStateChange = useCallback(
    (
      fn: (
        prevState: $Shape<MonitoringHistoryState>
      ) => $Shape<MonitoringHistoryState>
    ) => {
      setHistoryState((prevState) => ({ ...prevState, ...fn(prevState) }));
    },
    []
  );

  /**
   * Подгружает данные трека для истории
   */
  const fetchTrack = useCallback(
    async (vehicle: MonitoringVehicle) => {
      if (historyState.startDate && historyState.endDate) {
        setLoading(true);
        const { messages } = await wialonApi().sections.Messages.loadInterval({
          itemId: vehicle.itemId,
          // $FlowFixMe
          timeFrom: new Date(historyState.startDate).getTime() / 1000,
          // $FlowFixMe
          timeTo: new Date(historyState.endDate).getTime() / 1000,
          flags: 1,
          flagsMask: 65281,
          loadCount: 4294967295,
        });
        if (messages) {
          try {
            setPlayback(null);
            handleStateChange(() => {
              return {
                history: _orderBy(
                  messages.map((message) => {
                    return {
                      status: 'active',
                      timestamp: new Date(message.t * 1000),
                      geoPosition: {
                        lat: message?.pos?.y,
                        lng: message?.pos?.x,
                      },
                      direction: message?.pos?.c,
                      speed: message?.pos?.s,
                      state: message?.pos?.s > 0 ? 'inmove' : 'stopped',
                    };
                  }),
                  // historyPoints.map(v => ({
                  //   ...v,
                  //   timestamp: new Date(v.timestamp)
                  // })),
                  'timestamp',
                  'asc'
                ),
              };
            });
          } catch (e) {
            notification.error({ message: 'Ошибка', description: e.message });
          } finally {
            setLoading(false);
          }
        } else {
          handleStateChange(() => {
            return {
              history: [],
            };
          });
        }
      }
    },
    [handleStateChange, historyState.endDate, historyState.startDate]
  );

  /**
   * Обработка выбора ТС (в сайдбаре, на карте)
   */
  const handleVehicleSelect = useCallback(
    async (vehicle: MonitoringVehicle) => {
      try {
        if (!vehicle.itemId) throw new SyntaxError('ТС не привязано к виалону');
        const message = await fetchVehicleLastMessage(vehicle);
        await fetchTrack(vehicle);
        setSelectedMonitoringVehicle({
          ...vehicle,
          address: await geocode(message?.pos?.y, message?.pos?.x),
          speed: message?.pos?.s,
          direction: message?.pos?.c,
          geoPosition: {
            lat: message?.pos?.y,
            lng: message?.pos?.x,
          },
        });
        setVehicleInfoOpen(true);
      } catch (e) {
        notification.error({ message: 'Ошибка', description: e.message });
      }
    },
    [fetchTrack, fetchVehicleLastMessage]
  );

  /**
   *
   * @param {*} wialonItemId
   * @param {*} vehicle
   * Обработка преключения выбора ТС для отображения или нет на карте
   */
  const handleActiveMonitoringVehicles = (
    wialonItemId: number,
    vehicle: MonitoringVehicle
  ) => {
    let activeVehicles = activeMonitoringVehicles;
    if (activeVehicles.has(wialonItemId)) {
      activeVehicles.delete(wialonItemId);
    } else {
      activeVehicles.set(wialonItemId, vehicle);
    }
    setActiveMonitoringVehicles(new Map(activeVehicles));
  };

  /**
   * Очищает фильтр по ТС
   */
  const cleanFilter = useCallback(() => setVehicles(originalVehicles), [
    originalVehicles,
  ]);

  /**
   * Применяет фильтр по ТС
   * Локальная фильтрация
   */
  const applyFilter = useCallback(
    (filters: MonitoringFilterParams) => {
      const { stopped, disconnected, connected, moving } = filters;
      let filteredVehicles = originalVehicles;
      if (stopped) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle: MonitoringVehicle) =>
            vehicle.state === monitoringVehicleStatesEnum.stopped
        );
      }
      if (disconnected) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle: MonitoringVehicle) =>
            vehicle.status === monitoringVehicleStatusesEnum.nosignal
        );
      }
      if (connected) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle: MonitoringVehicle) =>
            vehicle.status === monitoringVehicleStatusesEnum.active
        );
      }
      if (moving) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle: MonitoringVehicle) =>
            vehicle.state === monitoringVehicleStatesEnum.inmove
        );
      }
      setVehicles(filteredVehicles);
    },
    [originalVehicles]
  );

  /**
   * Обработка закрытия информации по ТС
   */
  const closeVehicleInfo = useCallback(() => {
    setVehicleInfoOpen(false);
    // $FlowFixMe
    setSelectedMonitoringVehicle(undefined);
  }, []);

  /**
   * Обработка открытия истории
   */
  const handleHistoryOpen = useCallback(async (opened: boolean) => {
    setIsHistoryOpened(opened);
  }, []);

  useEffect(() => {
    if (selectedMonitoringVehicle) {
      fetchTrack(selectedMonitoringVehicle).then();
    }
  }, [
    historyState.startDate,
    historyState.endDate,
    selectedMonitoringVehicle,
    fetchTrack,
  ]);

  useEffect(() => {
    const wialon = wialonApi();
    async function mount() {
      try {
        // Логинимся в виалоне
        await wialon.login();
        closeSidebar();
        hideSidebarBurger();
        await fetchVehicles();
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    }
    function unmount() {
      // Убиваем инстанс проигрывателя
      playback && playback.dispose();
      // Подчищаемся в виалоне
      wialon && wialon.logout();
    }
    mount();
    return unmount;
    //eslint-disable-next-line
  }, [closeSidebar, hideSidebarBurger]);

  /**
   * Подготовка данных и инициализация плагина для
   * поддержки воспроизведения у leaflet карт
   */
  useEffect(() => {
    if (mapRef.current && isHistoryOpened && !playback) {
      const map = mapRef.current.leafletElement;
      const { history } = historyState;
      // Подготавливаем данные
      const data = history.map(
        ({ geoPosition: { lat, lng }, direction: dir, timestamp }, index) => ({
          lat,
          lng,
          time: parseInt((new Date(timestamp).getTime() / 1000).toFixed(0)),
          dir,
          info: [{ key: 'index', value: index }],
        })
      );
      // Инициализация плагина для воспроизведения маршрута
      const playback = new L.trackplayback(data, map, {
        clockOptions: {
          speed: basePlaybackSpeed,
        },
        targetOptions: {
          useImg: true,
          imgUrl: '/img/car.svg',
          height: 40,
          width: 28,
        },
      });
      // Обработка каждого тика воспроизведения
      playback.on(
        'tick',
        (item) => {
          const {
            target: { tracks },
          } = item;
          const [track] = tracks;
          const { _timeTick } = track;
          const ticks = Object.keys(_timeTick);
          // Ищем нужный тик по треку на основе тика проигрывателя
          const findedTick = findTick(ticks, item.time);
          // Находим индекс тика для отображения текущего элемента
          // истории в панеле (скорость, время, статус)
          const tickIndex = _timeTick[findedTick];
          handleStateChange(() => ({
            isEnded: playback.getCurTime() === playback.getEndTime(),
            currentIndex: tickIndex,
            currentTick: item.time,
          }));
        },
        this
      );
      setPlayback(playback);
    }
  }, [
    historyState.history,
    isHistoryOpened,
    playback,
    historyState,
    handleStateChange,
  ]);

  const { page, pageSize, totalCount } = pagination;

  return (
    <Wrapper>
      <Sidebar>
        <BackButton onClick={handleBackClick}>
          <Icon type="arrow-left" />
        </BackButton>
      </Sidebar>
      <Content>
        {loading && <Loading />}
        <VehiclesList
          handleActiveMonitoringVehicles={handleActiveMonitoringVehicles}
          activeMonitoringVehicles={activeMonitoringVehicles}
          onSelect={handleVehicleSelect}
          cleanFilter={cleanFilter}
          applyFilter={applyFilter}
          fetchVehicles={fetchVehicles}
          selectedMonitoringVehicle={selectedMonitoringVehicle}
          monitoringVehicles={vehicles}
          pagination={{
            location,
            page,
            pageSize,
            totalCount,
          }}
        />

        <MapWrapper>
          <MonitoringMap
            ref={mapRef}
            selectedMonitoringVehicle={selectedMonitoringVehicle}
            vehicles={Array.from(activeMonitoringVehicles.values())}
            history={historyState.history}
            currentHistoryItem={historyState.history[historyState.currentIndex]}
            isHistoryOpen={isHistoryOpened}
            onVehicleClick={handleVehicleSelect}
          />
          <VehicleInfo
            isHistoryOpened={isHistoryOpened}
            playback={playback}
            close={closeVehicleInfo}
            open={vehicleInfoOpen}
            monitoringVehicle={selectedMonitoringVehicle}
            historyState={historyState}
            onStateChange={handleStateChange}
            handleHistoryOpen={handleHistoryOpen}
          />
        </MapWrapper>
      </Content>
    </Wrapper>
  );
};

export default connect((state: AppState) => ({}), {
  hideSidebarBurger,
  closeSidebar,
  openSidebar,
  showSidebarBurger,
})(MonitoringScreen);
