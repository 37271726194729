// @flow
import { ExclamationCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { Link } from '@reach/router';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';

import Popover from 'antd/lib/popover';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { notificationLoading } from '../../../components/Notifications';
import { Popconfirm } from '../../../components/ui';
import { vehiclePlanApi } from '../../../lib/api';
import {
  calculationStatusEnum,
  vehicleTypeEnum,
  vehicleTypes,
} from '../../../lib/enum';
import {
  formatDate,
  getPathWithHistoryParams,
  minus,
  navigate,
  plus,
  toLocalStringRu,
} from '../../../lib/helpers';

import type { UserAccess, VehicleModel, VehicleType } from '../../../lib/types';

import { withUserAccess } from '../../withUserAccess';

import { Header, TopPanel } from './../../../components/layout';
import Breadcrumbs, { Crumb } from './../../../components/layout/Breadcrumbs';
import { canApprovingVehiclePlan } from './accessRight';

import type { VehicleListFilterParams } from './components/FilterVehicleList';
import MainTab from './components/Tab';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Model = styled.div`
  width: 268px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const LicensePlate = styled.div`
  flex: 1;
`;

const IconWarnStyle = {
  fontSize: '13px',
  position: 'relative',
  marginLeft: '10px',
  top: '4px',
};

export const DecomissioningDateWarn = (props: { date?: string }) => {
  return (
    <Popover
      content={<>Планируемая дата списания: {formatDate(props.date)}</>}
      placement="right"
    >
      <ExclamationCircleTwoTone twoToneColor="red" style={IconWarnStyle} />
    </Popover>
  );
};
export const OverhaulRepairCostWarn = () => (
  <Popover
    content={<>Нет цены у некоторых материалов для проведения капремонта</>}
    placement="right"
  >
    <WarningTwoTone twoToneColor="red" style={IconWarnStyle} />
  </Popover>
);

export const toGibdd = (vehicleType?: VehicleType) => {
  return vehicleType
    ? ![
        vehicleTypeEnum.bkmNaTraktore,
        vehicleTypeEnum.bkmRpnNaAvtomobyle,
        vehicleTypeEnum.bgmNaTraktore,
        vehicleTypeEnum.traktorTiagach,
        vehicleTypeEnum.traktorPum,
        vehicleTypeEnum.traktorBuldozer,
        vehicleTypeEnum.ekskavatorVKovshaDo05M3,
        vehicleTypeEnum.ekskavatorVKovshaSvyshe05M3,
        vehicleTypeEnum.gydrotsykl,
        vehicleTypeEnum.lodkaSMotorom,
        vehicleTypeEnum.lodkaVesla,
        vehicleTypeEnum.prytsepTraktornyi,
        vehicleTypeEnum.snegoxod,
      ].includes(vehicleType)
    : false;
};

export const toGtn = (vehicleType?: VehicleType) => {
  return vehicleType
    ? [
        vehicleTypeEnum.bkmNaTraktore,
        vehicleTypeEnum.bkmRpnNaTraktore,
        vehicleTypeEnum.bgmNaTraktore,
        vehicleTypeEnum.traktorTiagach,
        vehicleTypeEnum.traktorPum,
        vehicleTypeEnum.traktorBuldozer,
        vehicleTypeEnum.ekskavatorVKovshaDo05M3,
        vehicleTypeEnum.ekskavatorVKovshaSvyshe05M3,
        vehicleTypeEnum.prytsepTraktornyi,
        vehicleTypeEnum.snegoxod,
      ].includes(vehicleType)
    : false;
};

export const toGpmAndExpertise = (vehicleType?: VehicleType) => {
  return vehicleType
    ? [
        vehicleTypeEnum.agpVyletStrelyDo28M,
        vehicleTypeEnum.agpVyletStrelySvyshe28M,
        vehicleTypeEnum.agpRpnVyletStrelyDo28M,
        vehicleTypeEnum.agpRpnVyletStrelySvyshe28M,
        vehicleTypeEnum.avtokranGPDo24T,
        vehicleTypeEnum.avtokranGPOt25TDo49T,
        vehicleTypeEnum.avtokranGPSvyshe50T,
        vehicleTypeEnum.bkmNaAvtomobyle,
        vehicleTypeEnum.bkmRpnNaAvtomobyle,
        vehicleTypeEnum.bkmNaTraktore,
        vehicleTypeEnum.bkmRpnNaTraktore,
        vehicleTypeEnum.bgmNaTraktore,
        vehicleTypeEnum.gruzovoiBortovoiKlassaN2Do7Tn,
        vehicleTypeEnum.gruzovoiBortovoiKlassaN3Svyshe7Tn,
        vehicleTypeEnum.avtomobylTiagachSKmu,
        vehicleTypeEnum.gruzopassazhyrskyiAvtomobylSKmu,
        vehicleTypeEnum.prytsepSKmu,
        vehicleTypeEnum.pPrytsepSKmu,
      ].includes(vehicleType)
    : false;
};

export const gps = (vehicleType?: VehicleType) => {
  return vehicleType
    ? ![
        vehicleTypeEnum.lodkaSMotorom,
        vehicleTypeEnum.lodkaVesla,
        vehicleTypeEnum.gydrotsykl,
        vehicleTypeEnum.prytsepLegkovoiGPDo750Kg,
        vehicleTypeEnum.prytsepAvtomobylnyi,
        vehicleTypeEnum.prytsepTraktornyi,
        vehicleTypeEnum.prytsepSKmu,
        vehicleTypeEnum.pPrytsepBezKmu,
        vehicleTypeEnum.pPrytsepSKmu,
        vehicleTypeEnum.prytsepRospusk,
        vehicleTypeEnum.prytsepDliaDgu,
        vehicleTypeEnum.prytsepKabelnyi,
        vehicleTypeEnum.prytsepYzmelchytel,
        vehicleTypeEnum.prytsepKompressor,
        vehicleTypeEnum.prytsepTsysterna,
        vehicleTypeEnum.prytsepGenerator,
        vehicleTypeEnum.snegoxod,
        vehicleTypeEnum.kvadrotsykl,
        vehicleTypeEnum.bolotoxod,
        vehicleTypeEnum.car,
        vehicleTypeEnum.legkovoiPolnopryvodnyi,
      ].includes(vehicleType)
    : false;
};

export const measuringDevices = (vehicleType?: VehicleType) => {
  return [
    vehicleTypeEnum.avtomobylMaslovozVDo5M3,
    vehicleTypeEnum.avtomobylMaslovozVSvyshe5M3,
    vehicleTypeEnum.avtomobylAssenyzatorVDo5M3,
    vehicleTypeEnum.avtomobylAssenyzatorVSvyshe5M3,
  ].includes(vehicleType);
};

export type VehicleTypeData = 'selfVehicles' | 'plannedVehicles';
export const MONTH = [
  { name: 'january', title: 'Январь' },
  { name: 'february', title: 'Февраль' },
  { name: 'march', title: 'Март' },
  { name: 'april', title: 'Апрель' },
  { name: 'may', title: 'Май' },
  { name: 'june', title: 'Июнь' },
  { name: 'july', title: 'Июль' },
  { name: 'august', title: 'Август' },
  { name: 'september', title: 'Сентябрь' },
  { name: 'october', title: 'Октябрь' },
  { name: 'november', title: 'Ноябрь' },
  { name: 'december', title: 'Декабрь' },
];

export const itogCalculation = (data: any) => {
  const itog = {
    // $FlowFixMe
    months: Array(12)
      .fill()
      .map(() => ({ cost: 0 })),
    sum: 0,
  };
  data.forEach((item: any) => {
    itog.sum = plus(itog.sum, item.sum);
    item.months.forEach((month: any, index) => {
      if (index < 12)
        itog.months[index].cost = plus(itog.months[index].cost, month.cost);
    });
  });
  return itog;
};

export const COLUMNS = [
  {
    title: 'Марка и модель',
    dataIndex: ['selfVehiclePlanVehicle', 'vehicle', 'vehicleModel'],
    // dataIndex: 'selfVehiclePlanVehicle.vehicle.vehicleModel',
    width: 300,
    key: 'vehicleModel',
    sorter: (a: any, b: any) => {
      const first = a?.selfVehiclePlanVehicle?.vehicle?.vehicleModel ?? false;
      const second = b?.selfVehiclePlanVehicle?.vehicle?.vehicleModel ?? false;
      return first && second
        ? `${first.brandName} ${first.name}`.localeCompare(
            `${second.brandName} ${second.name}`
          )
        : false;
    },
    fixed: 'left',
    render: (vehicleModel: VehicleModel) => {
      return vehicleModel ? (
        <Model>{`${vehicleModel.brandName} ${vehicleModel.name}`}</Model>
      ) : (
        'Итого: '
      );
    },
  },
  {
    title: 'Гос. номер',
    width: 200,
    key: 'licensePlate',
    fixed: 'left',
    dataIndex: 'selfVehiclePlanVehicle.vehicle.licensePlate',
    render: (licensePlate: string, record: any) => {
      return (
        licensePlate && (
          <Container>
            <Link to={`/vehicles/${record.selfVehiclePlanVehicle.vehicleId}`}>
              {licensePlate}
            </Link>
            {record?.selfVehiclePlanVehicle?.plannedWriteoffDate && (
              <DecomissioningDateWarn
                date={record.selfVehiclePlanVehicle.plannedWriteoffDate}
              />
            )}
          </Container>
        )
      );
    },
    sorter: (a: any, b: any) => {
      const first = a?.selfVehiclePlanVehicle?.vehicle?.licensePlate ?? '0';
      const second = b?.selfVehiclePlanVehicle?.vehicle?.licensePlate ?? '0';
      return a.selfVehiclePlanVehicle !== undefined
        ? first.localeCompare(second)
        : false;
    },
  },
  {
    title: 'Тип ТС',
    width: 300,
    key: 'type',
    dataIndex: 'selfVehiclePlanVehicle.vehicle.vehicleModel.type',
    sorter: (a: any, b: any) => {
      const first =
        a?.selfVehiclePlanVehicle?.vehicle?.vehicleModel.type ?? false;
      const second =
        b?.selfVehiclePlanVehicle?.vehicle?.vehicleModel.type ?? false;
      return first && second
        ? vehicleTypes[first].localeCompare(vehicleTypes[second])
        : false;
    },
    render: (type: VehicleType) => type && vehicleTypes[type],
  },
  {
    title: 'Год выпуска',
    width: 110,
    key: 'yearIssued',
    sorter: (a: any, b: any) => {
      const first = a?.selfVehiclePlanVehicle?.vehicle?.yearIssued ?? 0;
      const second = b?.selfVehiclePlanVehicle?.vehicle?.yearIssued ?? 0;
      return a.selfVehiclePlanVehicle !== undefined
        ? minus(first, second)
        : false;
    },
    dataIndex: 'selfVehiclePlanVehicle.vehicle.yearIssued',
  },
];

export const COLUMNS_MONTH = [
  ...COLUMNS,
  ...MONTH.map((month, index) => ({
    title: month.title,
    width: '150',
    dataIndex: ['months', index, 'cost'],
    render: (cost: number) => toLocalStringRu(cost),
  })),
  {
    title: 'Итог',
    dataIndex: 'sum',
    render: (sum: number) => toLocalStringRu(sum),
  },
];

export const filteredData = (data: any, filter: VehicleListFilterParams) =>
  data
    .filter((selfVehicle) =>
      filter.vehicleId
        ? filter.vehicleId === selfVehicle.selfVehiclePlanVehicle?.vehicleId
        : true
    )
    .filter((selfVehicle) =>
      filter.type
        ? filter.type ===
          selfVehicle.selfVehiclePlanVehicle?.vehicle.vehicleModel.type
        : true
    )
    .filter((selfVehicle) =>
      filter.vehicleModelId
        ? filter.vehicleModelId ===
          selfVehicle.selfVehiclePlanVehicle?.vehicle.vehicleModelId
        : true
    )
    .filter((selfVehicle) =>
      filter.yearIssued
        ? filter.yearIssued ===
          selfVehicle.selfVehiclePlanVehicle?.vehicle.yearIssued
        : true
    )
    .filter((selfVehicle) => {
      return filter.hideEmpty === true ? selfVehicle.sum !== 0 : true;
    });

export const filteredTaxData = (data: any, filter: VehicleListFilterParams) =>
  data
    .filter((selfVehicle) =>
      filter.vehicleId ? filter.vehicleId === selfVehicle.vehicleId : true
    )
    .filter((selfVehicle) =>
      filter.type ? filter.type === selfVehicle.vehicle.vehicleModel.type : true
    )
    .filter((selfVehicle) =>
      filter.vehicleModelId
        ? filter.vehicleModelId === selfVehicle.vehicle.vehicleModelId
        : true
    )
    .filter((selfVehicle) =>
      filter.yearIssued
        ? filter.yearIssued === selfVehicle.vehicle.yearIssued
        : true
    )
    .filter((selfVehicle) => {
      return filter.hideEmpty === true ? selfVehicle.tax !== 0 : true;
    });

type CreateBudgetProps = {
  vehiclePlanId: number,
  userAccess: UserAccess[],
};
export const CreateBudget = (props: CreateBudgetProps) => {
  const { vehiclePlanId, userAccess } = props;
  const [name, setName] = useState(null);
  const [vehiclePlan, setVehiclePlan] = useState(null);

  const onCreateBudget = async () => {
    try {
      if (name) {
        notificationLoading({
          message: 'Сформировываем предложение в бюджет...',
          key: 'createBudget',
        });
        await vehiclePlanApi.createBudget({ name, id: vehiclePlanId });
        setName(null);
        navigate(`/budget/vehicle/${vehiclePlanId}/itog`);
      } else {
        throw new Error('Введите наименование бюджета');
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('createBudget');
    }
  };

  const getVehiclePlan = useCallback(async () => {
    const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
    setVehiclePlan(vehiclePlan);
  }, [vehiclePlanId]);

  useEffect(() => {
    if (vehiclePlanId) {
      getVehiclePlan();
    }
  }, [getVehiclePlan, vehiclePlanId]);

  return (
    vehiclePlan?.budgetStatus === calculationStatusEnum.draft &&
    canApprovingVehiclePlan(userAccess) && (
      <Popconfirm
        overlayStyle={{ zIndex: 2000 }}
        placement="left"
        title={
          <Input
            placeholder="Введите наименование бюджета"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        }
        onConfirm={onCreateBudget}
      >
        <Button type="primary">Сформировать предложение</Button>
      </Popconfirm>
    )
  );
};

export type PropsHeader = {
  title: string,
  vehiclePlanId: number,
  mainTab?: boolean,
  panel?: Function,
};

export const commonHeaderPanel = ({
  mainTab = true,
  ...props
}: PropsHeader) => {
  const { vehiclePlanId, panel } = props;
  const CreateBudgetComponent = withUserAccess(CreateBudget);
  return (
    <>
      <Header
        left={
          <Breadcrumbs>
            <Crumb to="/">Главная</Crumb>
            <Crumb to={getPathWithHistoryParams('/budget/vehicle')}>
              Бюджет СТС
            </Crumb>
            <Crumb>{props.title}</Crumb>
          </Breadcrumbs>
        }
        right={
          <div style={{ minHeight: 32 }}>
            {mainTab && <CreateBudgetComponent vehiclePlanId={vehiclePlanId} />}
          </div>
        }
      />
      <TopPanel>{panel ? panel() : <h1>{props.title}</h1>}</TopPanel>
      {mainTab && <MainTab vehiclePlanId={vehiclePlanId} />}
    </>
  );
};
