// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import qs from 'query-string';
import { connect } from 'react-redux';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';

import {
  osagoCalculationApi,
  type FetchListParams,
  osagoCalculationYearApi,
} from './../../../lib/api';
import type {
  OsagoCalculation,
  ListState,
  VehicleModel,
  UserAccess,
} from '../../../lib/types';
import {
  getListInitialState,
  setQueryParams,
  applyMaskToValue,
} from './../../../lib/helpers';
import type {
  OsagoCalculationStatus,
  OsagoCalculationYear,
} from '../../../lib/types/osagoCalculation';
import {
  osagoCalculationStatusEnum,
  vehicleGroups,
  vehicleTypes,
} from './../../../lib/enum';
import { accessTypeEnum } from '../../../lib/enum/userAccess';

import { Popconfirm, Icon, Table } from './../../../components/ui';
import { Section } from './../../../components/layout';
import Header from './../../../components/layout/Header';
import { notificationLoading } from '../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import type { AppState } from '../../../ducks/redux';

import Filter, { type OsagoCalculationFilterParams } from './Filter';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;
const Operations = styled.div`
  & * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
};

type State = ListState<OsagoCalculation> & {
  filter: OsagoCalculationFilterParams,
  osagoCalculationYear: ?OsagoCalculationYear,
};

export class OsagoCalculationsList extends PureComponent<Props, State> {
  state = {
    ...getListInitialState(),
    osagoCalculationYear: null,
    filter: {},
  };

  async deleteOsagoCalculation(id: number) {
    const { page } = this.state;
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await osagoCalculationApi.deleteOsagoCalculation(id);
      notification.success({
        message: 'Успешное удаление',
        description: `Запись расчета успешна удалена`,
      });
      await this.fetchOsagoCalculations(page);
    } catch (error) {
      notification.error({
        message: 'Ошибка при удалении',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  columns = [
    {
      title: 'Тип/Модель',
      dataIndex: 'vehicleModel',
      fixed: 'left',
      sorter: true,
      render: (vehicleModel: VehicleModel, record: OsagoCalculation) => {
        if (record.vehicle) {
          return [
            record.vehicle.vehicleModel.brandName,
            record.vehicle.vehicleModel.name,
          ]
            .join(' ')
            .trim();
        }
        if (vehicleModel) {
          return [vehicleModel.brandName, vehicleModel.name].join(' ').trim();
        }
        return record.vehicleType
          ? vehicleTypes[record.vehicleType]
          : vehicleGroups[record.vehicleGroup];
      },
    },
    {
      title: 'Гос. номер',
      dataIndex: 'vehicle.licensePlate',
      fixed: 'left',
      sorter: true,
      render: (licensePlate: ?string, record: any) =>
        licensePlate && (
          <Link to={`/vehicles/${record.vehicle.id}`}>
            {applyMaskToValue(licensePlate, formatLicensePlateMask)}
          </Link>
        ),
    },
    {
      title: 'Год выпуска',
      dataIndex: 'vehicle.yearIssued',
      render: (yearIssued: number, record: OsagoCalculation) => {
        if (yearIssued) {
          return yearIssued;
        }
        return record.yearIssued;
      },
    },
    {
      title: 'Расчетный год',
      dataIndex: 'year',
    },
    {
      title: 'Базовая ставка (тек.)',
      dataIndex: 'currentBaseRate',
      sorter: true,
    },
    {
      title: 'Базовая ставка (мин)',
      dataIndex: 'minBaseRate',
      sorter: true,
    },
    {
      title: 'Базовая ставка (макс)',
      dataIndex: 'maxBaseRate',
      sorter: true,
    },
    {
      title: 'КТ',
      dataIndex: 'kt',
      sorter: true,
    },
    {
      title: 'КБМ',
      dataIndex: 'kbm',
      sorter: true,
    },
    {
      title: 'КО',
      dataIndex: 'ko',
      sorter: true,
    },
    {
      title: 'КВС',
      dataIndex: 'kvs',
      sorter: true,
    },
    {
      title: 'КС',
      dataIndex: 'kc',
      sorter: true,
    },
    {
      title: 'КП',
      dataIndex: 'kp',
      sorter: true,
    },
    {
      title: 'КМ',
      dataIndex: 'km',
      sorter: true,
    },
    {
      title: 'КПР',
      dataIndex: 'kpr',
      sorter: true,
    },
    {
      title: 'Сумма по текущему значению',
      dataIndex: 'curInsuranceSum',
      sorter: true,
    },
    {
      title: 'Сумма по минимальной ставке',
      dataIndex: 'minInsuranceSum',
      sorter: true,
    },
    {
      title: 'Сумма по максимальной ставке',
      dataIndex: 'maxInsuranceSum',
      sorter: true,
    },
  ];

  fetchOsagoCalculations = async (
    page: number = 1,
    params: FetchListParams<OsagoCalculationFilterParams> = {}
  ) => {
    try {
      const { filter } = this.state;
      this.setState({ loading: true });

      const { data, totalCount } =
        await osagoCalculationApi.fetchOsagoCalculations({
          ...filter,
          page,
          ...params,
        });
      setQueryParams({ page });
      this.setState({ data, totalCount, page });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  cleanFilter = () => {
    const { osagoCalculationYearId } = this.state.filter; // год не сбрасываем
    parseInt(osagoCalculationYearId) > 0 &&
      setQueryParams({ osagoCalculationYearId });
    this.setState(
      { filter: { osagoCalculationYearId } },
      this.fetchOsagoCalculations
    );
  };

  applyFilter = (filter: OsagoCalculationFilterParams) =>
    this.setState({ filter }, this.fetchOsagoCalculations);

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    const { osagoCalculationYearId } = qs.parse(this.props.location.search);
    const osagoCalculationYear =
      await osagoCalculationYearApi.getOsagoCalculationYear(
        osagoCalculationYearId
      );
    this.setState({ filter, osagoCalculationYear }, () =>
      this.fetchOsagoCalculations(page)
    );
    if (this.canHandle()) {
      this.columns.push({
        title: '',
        width: '30px',
        render: (record: OsagoCalculation) => (
          <Operations>
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() =>
                this.deleteOsagoCalculation(parseInt(record.id, 10))
              }
            >
              <StyledIcon type="x" />
            </Popconfirm>
          </Operations>
        ),
      });
    }
  }

  handleRowClick = (id: number) => navigate(`/budget/osago-calculations/${id}`);

  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'print',
      });
      const { osagoCalculationYearId } = qs.parse(this.props.location.search);
      await osagoCalculationApi.print(osagoCalculationYearId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  canHandle = () => {
    return this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.formationOsagoCalculations,
      ].includes(access)
    );
  };

  canApprove = () => {
    return this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingOsagoCalculation,
      ].includes(access)
    );
  };

  /**
   * Смена статуса года
   * @param status Статус
   */
  changeStatus = async (status: OsagoCalculationStatus) => {
    // $FlowFixMe flow говоритБ что нет параметра osagoCalculationYear
    const { osagoCalculationYear } = this.state;
    const changed =
      await osagoCalculationYearApi.changeOsagoCalculationStatusYear({
        ...osagoCalculationYear,
        status,
      });
    this.setState({ osagoCalculationYear: changed });
  };

  /**
   * Утверждение расчета потребностей ОСАГО
   */
  handleApprove = async () => {
    try {
      await this.changeStatus(osagoCalculationStatusEnum.approved);
      const { year } = this.state.osagoCalculationYear || {};
      notification.success({
        message: `Расчет потребностей ОСАГО за ${year} год был утвержден`,
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  /**
   * Отправление на утверждение расчета потребностей ОСАГО
   */
  handleSendOnApprove = async () => {
    try {
      await this.changeStatus(osagoCalculationStatusEnum.onApproving);
      const { year } = this.state.osagoCalculationYear || {};
      notification.success({
        message: `Расчет потребностей ОСАГО за ${year} год был отправлен на утверждение`,
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      page,
      pageSize,
      loading,
      filter,
      osagoCalculationYear,
    } = this.state;

    const canHandle = this.canHandle();
    const canApprove = this.canApprove();
    const { status, year } = osagoCalculationYear || {};

    return (
      <>
        <Header
          left={<h1>Расчет потребностей ОСАГО за {year} год</h1>}
          right={
            <Operations>
              {canHandle && status === osagoCalculationStatusEnum.draft && (
                <Button onClick={this.handleSendOnApprove} type="primary">
                  На утверждение
                </Button>
              )}
              {canApprove && status === osagoCalculationStatusEnum.onApproving && (
                <Button onClick={this.handleApprove} type="primary">
                  Утвердить
                </Button>
              )}
              <Button onClick={this.handlePrint}>Печать</Button>
            </Operations>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data.map((model) => ({
              ...model,
              key: model.id,
            }))}
            fetch={this.fetchOsagoCalculations}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect((state: AppState) => ({
  userAccess: state.auth.profile.access,
}))(OsagoCalculationsList);
