// @flow

// FIXME энумы должны быть энумами(юнионами), а переводы вынесены в отдельные функции

export { osagoTransportType } from './osagoBranchTypes';
export { fuelGroupEnum, fuelGroups } from './fuelGroup';
export { fuelTypeEnum, fuelTypes, fuelTypesCalculation } from './fuelType';
export { businessDayTypeEnum, businessDayTypes } from './businessDay';
export { tariffTypeEnum, tariffTypes } from './tariffType';
export { operatingModeEnum, operatingModes } from './operatingMode';
export { accessTypeEnum, accessTypes } from './userAccess';
export {
  oldVehicleTypes,
  vehicleTypeEnum,
  vehicleTypes,
  gpmVehicleTypes,
  oldVehicleTypeEnum,
  lulechnikVehicleTypes,
  riggerVehicleTypes,
  trailerVehicleTypes,
  vehicleGroups,
  vehicleGroupEnum,
  safetyCarLiftTypes,
  avtokranTypes,
} from './vehicleTypes';
export { serviceTypes, serviceTypeEnum } from './serviceTypes';
export {
  driverQualificationEnum,
  driverQualifications,
} from './driverQualification';

export const attachedEquipmentFuelTankType = {
  vehicleTank: 'Бак ТС',
  ownTank: 'Собственный бак',
};

export const attachedEquipmentFuelTankTypeEnum = {
  vehicleTank: 'vehicleTank',
  ownTank: 'ownTank',
};

export const vehiclePtsType = {
  electronic: 'Электронный',
  paper: 'Бумажный',
};

export const vehiclePtsTypeEnum = {
  electronic: 'electronic',
  paper: 'paper',
};

export const expenseDirectionCognosStatus = {
  error: 'Ошибка при обработке запроса',
  notProcessed: 'Направление расходов еще не обработано',
  approved: 'Направление расходов утверждено',
  declined: 'Направление расходов отклонено',
};

export const expenseDirectionCognosStatusEnum = {
  error: 'error',
  notProcessed: 'notProcessed',
  approved: 'approved',
  declined: 'declined',
};

export const ISUBudgetingData = {
  activityDirection: 'Направление деятельности',
  itemOfCalculation: 'Статья калькуляции',
  specialMark: 'Особая отметка',
  typeOfMovementByElement: 'Вид движения по элементу',
};

export const ISUBudgetingDataTypeEnum = {
  activityDirection: 'activityDirection',
  itemOfCalculation: 'itemOfCalculation',
  specialMark: 'specialMark',
  typeOfMovementByElement: 'typeOfMovementByElement',
};

export const taxRates = {
  zero: '0%',
  ten: '10%',
  twenty: '20%',
};

export const taxRatesEnum = {
  zero: 'zero',
  ten: 'ten',
  twenty: 'twenty',
};

export const cognosContractMonth = {
  currentYear: 'Текущий год',
  pastYear: 'Прошлый год',
  nextYear: 'Следующий год',
};

export const cognosContractMonthEnum = {
  currentYear: 'currentYear',
  pastYear: 'pastYear',
  nextYear: 'nextYear',
};

export const branchBudgetSummaryCognosStatus = {
  notProcessed: 'Не отправлен',
  approved: 'Утвержден',
  partiallyApproved: 'Утверждены не все направления',
  declined: 'Отклонен',
  sended: 'Отправлен',
  approving: 'Ожидает утверждения',
  error: 'Ошибка',
};

export const branchBudgetSummaryCognosStatusEnum = {
  notProcessed: 'notProcessed',
  approved: 'approved',
  partiallyApproved: 'partiallyApproved',
  declined: 'declined',
  sended: 'sended',
  approving: 'approving',
  error: 'error',
};

export const seasonTypeTire = {
  summer: 'Лето',
  winter: 'Зима',
  all: 'Всесезонные',
  lowPressure: 'Низкого давления',
};

export const seasonTypeTireEnum = {
  summer: 'summer',
  winter: 'winter',
  all: 'all',
  lowPressure: 'lowPressure',
};

export const expenseDirectionDisplayRuleType = {
  earlierMonth: 'месяц раньше',
  currentMonth: 'текущий месяц',
  laterMonth: 'месяц позже',
};

export const expenseDirectionDisplayRuleTypeEnum = {
  earlierMonth: 'earlierMonth',
  currentMonth: 'currentMonth',
  laterMonth: 'laterMonth',
};

export const selfVehiclePlanVehicleType = {
  all: 'Все',
  kilometrage: 'Километры',
  workHours: 'Моточасы',
};

export const selfVehiclePlanVehicleTypeEnum = {
  all: 'all',
  kilometrage: 'kilometrage',
  workHours: 'workHours',
};

export const calculationStatus = {
  draft: 'Черновик',
  calculationDone: 'Рассчитан',
};

export const calculationStatusEnum = {
  draft: 'draft',
  calculationDone: 'calculationDone',
};

export const passType = {
  springRoad: 'Пропуск на проезд по дорогам в весенний период',
  cargo: 'Пропуск на перевозки тяжеловесных и крупногабаритных грузов',
};

export const passTypeEnum = {
  springRoad: 'springRoad',
  cargo: 'cargo',
};

export const vehicleTariffEnum = {
  byBrand: 'byBrand',
  byType: 'byType',
};

export const contractVehicleType = {
  fixed: 'Закрепленное',
  fixedPlan: 'Закрепленное (план)',
  oneOff: 'Разовые',
  oneOffPlan: 'Разовые (план)',
  substitution: 'Подмена',
  indefined: 'Неопределен',
};

export const contractVehicleTypeEnum = {
  fixed: 'fixed',
  fixedPlan: 'fixedPlan',
  oneOff: 'oneOff',
  oneOffPlan: 'oneOffPlan',
  substitution: 'substitution',
  indefined: 'indefined',
};

// ед. измерения для прейскуранта ГСМ
export const measureTypeFuelPrice = {
  liter: 'Литр',
  kilogram: 'Килограмм',
};

export const measureTypeFuelPriceEnum = {
  liter: 'liter',
  kilogram: 'kilogram',
};

// тип тарифа для прейскуранта ГСМ
export const rateType = {
  approved: 'Утвержденный',
  planned: 'Планируемый',
};

export const rateTypeEnum = {
  approved: 'approved',
  planned: 'planned',
};

export const maintenanceDocumentType = {
  defectiveStatement: 'Деф. ведомость',
  detailedDefectiveStatement: 'Дет. деф. ведомость',
  os3: 'os3',
};

export const maintenanceDocumentTypeEnum = {
  defectiveStatement: 'defectiveStatement',
  detailedDefectiveStatement: 'detailedDefectiveStatement',
  os3: 'os3',
};

export const nomenclatureType = {
  service: 'Услуга',
  product: 'Товар',
};

export const nomenclatureTypeEnum = {
  service: 'service',
  product: 'product',
};

export const measureType = {
  piece: 'Шт',
  service: 'Услуга',
};

export const measureTypeEnum = {
  piece: 'piece',
  service: 'service',
};

export const contractDocumentType = {
  contract: 'Договор',
  account: 'Лицевой счет',
};
export const contractDocumentTypeEnum = {
  contract: 'contract',
  account: 'account',
};

// Общие статусы по возможности юзаем их
export const entityStatus = {
  created: 'Черновик',
  approvement: 'На согласовании',
  approved: 'Утвержден',
  declined: 'Отклонен',
  editing: 'На редактирование',
};
export const entityStatusEnum = {
  created: 'created',
  approvement: 'approvement',
  approved: 'approved',
  declined: 'declined',
  editing: 'editing',
};

export const bioTreatmentFacilityFactStatus = {
  repaid: 'Погашен',
  notFound: 'Не найден',
  notPaid: 'Не оплачен',
  repaidWithError: 'Погашено с ошибкой',
  redeemedOperator: 'Погашен оператором',
  canceled: 'Отменен',
};

export const bioTreatmentFacilityFactStatusEnum = {
  repaid: 'repaid',
  notFound: 'notFound',
  notPaid: 'notPaid',
  repaidWithError: 'repaidWithError',
  redeemedOperator: 'redeemedOperator',
  canceled: 'canceled',
};

export const bioTreatmentFacilityTalonStatus = {
  ...entityStatus,
  closed: 'Закрыт',
};

export const bioTreatmentFacilityTalonStatusEnum = {
  ...entityStatusEnum,
  closed: 'closed',
};

// Статусы для перечня ТС
export const vehiclePlanStatus = {
  created: 'Сформирован перечень ТС',
  approvement: 'На согласовании',
  approved: 'Утверждён перечень ТС',
  declined: 'Отклонен',
  budgetCreated: 'Сформировано предложение',
};
export const vehiclePlanStatusEnum = {
  created: 'created',
  approvement: 'approvement',
  approved: 'approved',
  declined: 'declined',
  budgetCreated: 'budgetCreated',
};

export const actStatus = {
  created: 'Создан',
  signed: 'Подписан',
  approved: 'Согласован',
  workedOut: 'Отработан',
};
export const actStatusEnum = {
  created: 'created',
  signed: 'signed',
  approved: 'approved',
  workedOut: 'workedOut',
};

export const maintenanceMatrixValidAction = {
  required: 'Обязательно',
  allowed: 'Доступно',
  notAllowed: 'Не доступно',
};

export const maintenanceMatrixValidActionEnum = {
  required: 'required',
  allowed: 'allowed',
  notAllowed: 'notAllowed',
};

export const positions = {
  none: 'Отсутствует',
  safetyTechnician:
    'Специалист, ответственный за безопасное производство работ с применением автокрана (крана-манипулятора)',
  rigger: 'Стропальщик',
  mechanic: 'Механик',
  dispatcher: 'Диспетчер',
  engineer: 'Главный инженер',
  medic: 'Медицинский работник',
  lulechnik: 'Рабочий люльки',
  gpmController:
    'Специалист, ответственный за содержание ПС в работоспособном состоянии',
  truckCranesMachinist: 'Машинист крана(крановщик)',
  carLiftOperator: 'Машинист подъемника(вышки)',
  manipulatorCranesOperator: 'Оператор(машинист) крана-манипулятора',
  safetyCarLift:
    'Специалист, ответственный за безопасное производство работ с применением подъёмников (вышек)',
};

export const positionEnum = {
  none: 'none',
  safetyTechnician: 'safetyTechnician',
  rigger: 'rigger',
  mechanic: 'mechanic',
  engineer: 'engineer',
  dispatcher: 'dispatcher',
  medic: 'medic',
  lulechnik: 'lulechnik',
  gpmController: 'gpmController',
  truckCranesMachinist: 'truckCranesMachinist',
  carLiftOperator: 'carLiftOperator',
  manipulatorCranesOperator: 'manipulatorCranesOperator',
  safetyCarLift: 'safetyCarLift',
};

export const orgUnitTypes = {
  branch: 'Филиал',
  service: 'Служба',
};

export const vehicleStatuses = {
  draft: 'Черновик',
  working: 'В работе',
  sendingToParus: 'Отправка в Парус',
  limitExceed: 'Превышен лимит',
  onRepair: 'На ремонте',
  isArchive: 'Списано/Архив',
  onAgreeing: 'На согласовании',
  reserved: 'В резерве',
};

export const vehicleStatusEnum = {
  draft: 'draft',
  working: 'working',
  sendingToParus: 'sendingToParus',
  limitExceed: 'limitExceed',
  onRepair: 'onRepair',
  isArchive: 'isArchive',
  onAgreeing: 'onAgreeing',
  reserved: 'reserved',
};

export const budgetAnalysisTypes = {
  externalAct: 'Внешний акт',
  writeOffAct: 'Акт списания МПЗ',
  fuelsAct: 'Акт списания топлива',
  oilsAct: 'Акт списания масел',
  maintenance: 'Карточка работ',
};

export const budgetAnalysisTypesEnum = {
  externalAct: 'externalAct',
  writeOffAct: 'writeOffAct',
  fuelsAct: 'fuelsAct',
  oilsAct: 'oilsAct',
  maintenance: 'maintenance',
};

/**
 * Типы сущностей для журнала
 */
export const entityTypes = {
  order: 'Заявка',
  vehicle: 'ТС',
  trip: 'Путевой лист',
  contractVehicle: 'Наемный ТС',
  brand: 'Марка ТС',
  vehicleModel: 'Модель ТС',
  employee: 'Сотрудник',
  tire: 'Шина',
  battery: 'Аккумулятор',
  regulation: 'Регламент',
  contractVehiclePlan: 'Перечень НТС',
  assignment: 'Закрепление НТС',
  contractVehicleBudget: 'Бюджет НТС',
  contract: 'Контракт Учет БОС',
  bioTreatmentFacilityFact: 'Факт въезда',
  bioTreatmentFacilityVolume: 'Объем',
  vioTreatmentFacilityTalon: 'Талон',
  equipment: 'Навесное оборудование',
};

export const entityTypesEnum = {
  order: 'order',
  vehicle: 'vehicle',
  trip: 'trip',
  contractVehicle: 'contractVehicle',
  brand: 'brand',
  vehicleModel: 'vehicleModel',
  employee: 'employee',
  tire: 'tire',
  battery: 'battery',
  regulation: 'regulation',
  contractVehiclePlan: 'contractVehiclePlan',
  assignment: 'assignment',
  contractVehicleBudget: 'contractVehicleBudget',
  contract: 'contract',
  bioTreatmentFacilityFact: 'bioTreatmentFacilityFact',
  bioTreatmentFacilityVolume: 'bioTreatmentFacilityVolume',
  vioTreatmentFacilityTalon: 'vioTreatmentFacilityTalon',
  equipment: 'equipment',
};

/**
 * Тип документа сотрудника
 */
export const employeeDocumentTypeEnum = {
  rigger: 'rigger',
  lulechnik: 'lulechnik',
  safetyTechnician: 'safetyTechnician',
  safetyCarLift: 'safetyCarLift',
  controllerGpm: 'controllerGpm',
  manipulatorCranesOperator: 'manipulatorCranesOperator',
  truckCranesMachinist: 'truckCranesMachinist',
  carLiftOperator: 'carLiftOperator',
};

// Тип навесного оборудования
export const attachedEquipmentTypes = {
  ladle: 'Ковш',
  hydraulicHammer: 'Гидромолот',
  hydraulicShears: 'Гидроножницы',
  demolitionHammer: 'Бетонолом',
  multiprocessor: 'Мультипроцессор',
  grab: 'Грейфер',
  screwDrill: 'Шнековый бур',
  ripper: 'Рыхлитель',
  vibratoryPileDriver: 'Вибропогружатель',
  cutters: 'Фрезы',
  extraLong: 'Сверхдлинное навесное оборудование',
  mulcher: 'Мульчер',
  dozerBlade: 'Отвал бульдозерный',
  rotarySnowThrower: 'Снегоочиститель роторный',
  roadBrush: 'Щетка дорожная',
  sandSpreader: 'Разбрасыватель песка',
  plowThreeHingedMounted: 'Плуг трехкорпусный навесной',
  mountedDrillingRig: 'Навесная буровая установка',
  heater: 'Отопитель',
  preheater: 'Предпусковой подогреватель',
  generator: 'Генератор',
  weldingMachine: 'Сварочный аппарат',
};

// Тип дополнительного оборудования
export const optionalEquipmentTypes = {
  tachograph: 'Тахограф',
  dashboardCamera: 'Видеорегистратор',
  sideUnit: 'Бортовой блок',
};

export const orderObjectives = {
  maintenance: 'Обслуживание',
  shipping: 'Перевозка груза',
  passengerTransportation: 'Перевозка пассажиров',
  repair: 'Аварийный ремонт',
  liftingWork: 'Грузоподъёмные работы',
};

export const authorizeTypes = {
  ldap: 'Авторизация пользователя по LDAP',
  standard: 'Стандартная авторизация',
};

export const authorizeTypeEnum = {
  ldap: 'ldap',
  standard: 'standard',
};

export const orderObjectivesEnum = {
  maintenance: 'maintenance',
  shipping: 'shipping',
  passengerTransportation: 'passengerTransportation',
  repair: 'repair',
  liftingWork: 'liftingWork',
};

export const orderTypes = {
  standard: 'Стандартная',
  // @deprecated данный тип является устаревшим
  additional: 'Дополнительная',
  emergency: 'Аварийная',
};

export const orderTypeEnum = {
  standard: 'standard',
  // @deprecated данный тип является устаревшим
  additional: 'additional',
  emergency: 'emergency',
};

export const driverStatuses = {
  free: 'Свободен',
  busy: 'Занят',
};

export const tripStatuses = {
  draft: 'Черновик',
  created: 'Создан',
  opened: 'Открыт',
  verification: 'Закрыт',
  verified: 'Оттаксирован',
  canceled: 'Отменен',
  contractorReview: 'На рассмотрении',
};

export const tripStatusEnum = {
  draft: 'draft',
  created: 'created',
  opened: 'opened',
  verification: 'verification',
  verified: 'verified',
  canceled: 'canceled',
  contractorReview: 'contractorReview',
};

export const orderStatuses = {
  created: 'Создана',
  approvingByMainEngineer: 'На подтверждении у главного инженера',
  approvedByMainEngineer: 'Утверждено главным инженером',
  approved: 'Назначена',
  cancelled: 'Отменена',
  approvedByContractVehicleOrder: 'Назначена заявкой на доп. найм ТС',
};

export const orderStatusEnum = {
  created: 'created',
  approvingByMainEngineer: 'approvingByMainEngineer',
  approvedByMainEngineer: 'approvedByMainEngineer',
  approved: 'approved',
  cancelled: 'cancelled',
  approvedByContractVehicleOrder: 'approvedByContractVehicleOrder',
};

export const fuelAndOilView = {
  fuel: 'Топливо',
  lubricant: 'Смазки и масла',
  technicalFluid: 'Технические жидкости',
};

export const fuelAndOilViewEnum = {
  fuel: 'fuel',
  lubricant: 'lubricant',
  technicalFluid: 'technicalFluid',
};

export const fuelAndOilSubView = {
  diesel: 'Дизельное топливо',
  petrol: 'Бензин',
  gaseousFuel: 'Газобразное топливо',
  liquidOils: 'Масла жидкие',
  plasticLubricants: 'Смазки пластичные',
  coolingLiquid: 'Охлаждающие жидкости',
  brakeFluid: 'Тормозные жидкости',
  washingFluid: 'Промывочные жидкости',
  amortizationFluid: 'Амортизационные жидкости',
};

export const fuelAndOilSubViewEnum = {
  diesel: 'diesel',
  petrol: 'petrol',
  gaseousFuel: 'gaseousFuel',
  liquidOils: 'liquidOils',
  plasticLubricants: 'plasticLubricants',
  coolingLiquid: 'coolingLiquid',
  brakeFluid: 'brakeFluid',
  washingFluid: 'washingFluid',
  amortizationFluid: 'amortizationFluid',
};

export const fuelAndOilType = {
  motorOil: 'Моторные масла',
  transmissionOil: 'Трансмиссионные масла',
  industrialOil: 'Индустриальные масла',
  hydraulicOil: 'Гидравлические масла',
  lubricantsForConservation: 'Смазки консервационные',
  sealingLubricants: 'Смазки уплотнительные',
  antiGreaseLubricants: 'Антифракциооные смазки',
  tosol: 'Тосол',
  antifreeze: 'Антифриз',
  washingForEngineOilSystem: 'Промывка для маслянной системы двигателя',
};

export const fuelAndOilTypeEnum = {
  motorOil: 'motorOil',
  transmissionOil: 'transmissionOil',
  industrialOil: 'industrialOil',
  hydraulicOil: 'hydraulicOil',
  lubricantsForConservation: 'lubricantsForConservation',
  sealingLubricants: 'sealingLubricants',
  antiGreaseLubricants: 'antiGreaseLubricants',
  tosol: 'tosol',
  antifreeze: 'antifreeze',
  washingForEngineOilSystem: 'washingForEngineOilSystem',
};

export const fuelAndOilSubType = {
  oilForPetrolEngine: 'Моторные масла для бензиновых двигателей',
  oilForDieselEngine: 'Моторные масла для дизельных двигателей',
  fluidForGur: 'Жидкость для ГУРа',
};

export const fuelAndOilSubTypeEnum = {
  oilForPetrolEngine: 'oilForPetrolEngine',
  oilForDieselEngine: 'oilForDieselEngine',
  fluidForGur: 'fluidForGur',
};

export const fuelAndOilGroup = {
  summerDieselFuel: 'Дизельное топливо летнее',
  winterDieselFuel: 'Дизельное топливо зимнее',
  ai76: 'АИ-76',
  ai92: 'АИ-92',
  ai95: 'АИ-95',
  propaneButane: 'Пропан-Бутан',
  methane: 'Метан',
  mineralOil: 'Минеральное масло',
  semisyntheticOil: 'Полусинтетическое',
  synthetic: 'Синтетическое',
  shrus: 'Шрус',
  litol: 'Литол',
  am: 'АМ',
  a40m: 'А-40М',
  a65m: 'А65М',
  antifreezeGreen: 'Антифриз зеленый G11',
  antifreezeRed: 'Антифриз красный G12',
  antifreezeViolet: 'Антифриз фиолетовый G12++',
  dot2: 'DOT-2',
  dot3: 'DOT-3',
  dot4: 'DOT-4',
  dot5: 'DOT-5',
  fullVolume: 'Полнообъёмное',
  fiveMinute: '5-минутка',
  azh12t: 'АЖ-12т',
};

export const fuelAndOilGroupEnum = {
  summerDieselFuel: 'summerDieselFuel',
  winterDieselFuel: 'winterDieselFuel',
  ai76: 'ai76',
  ai92: 'ai92',
  ai95: 'ai95',
  propaneButane: 'propaneButane',
  methane: 'methane',
  mineralOil: 'mineralOil',
  semisyntheticOil: 'semisyntheticOil',
  synthetic: 'synthetic',
  shrus: 'shrus',
  litol: 'litol',
  am: 'am',
  a40m: 'a40m',
  a65m: 'a65m',
  antifreezeGreen: 'antifreezeGreen',
  antifreezeRed: 'antifreezeRed',
  antifreezeViolet: 'antifreezeViolet',
  dot2: 'dot2',
  dot3: 'dot3',
  dot4: 'dot4',
  dot5: 'dot5',
  fullVolume: 'fullVolume',
  fiveMinute: 'fiveMinute',
  azh12t: 'azh12t',
};

export const waypointTypeEnum = {
  // Начальная точка
  start: 'start',
  // Промежуточная точка
  transit: 'transit',
  // Конечная точка
  end: 'end',
};

export const insuranceClasses = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  fifth: 5,
  sixth: 6,
  seventh: 7,
  eighth: 8,
  ninth: 9,
  tenth: 10,
  eleventh: 11,
  twelfth: 12,
  thirteenth: 13,
};

export const repairPlanTypes = {
  planned: 'Плановый',
  unplanned: 'Внеплановый',
};

export const repairPlanEnum = {
  planned: 'planned',
  unplanned: 'unplanned',
};

export const medicOwnerTypes = {
  own: 'Собственные',
  hired: 'Наемные',
};

export const medicOwnerEnum = {
  own: 'own',
  hired: 'hired',
};

export const maintenanceTypes = {
  to1: 'ТО-1',
  to2: 'ТО-2',
  seasonTo: 'Сезонное ТО',
  seasonTo1: 'Сезонное ТО + ТО-1',
  replacementTire: 'Замена шин',
  replacementAkb: 'Замена АКБ',
  repairing: 'Ремонт',
};

export const maintenanceTypeEnum = {
  to1: 'to1',
  to2: 'to2',
  seasonTo: 'seasonTo',
  seasonTo1: 'seasonTo1',
  replacementTire: 'replacementTire',
  replacementAkb: 'replacementAkb',
  repairing: 'repairing',
};

export const serviceOrderTypes = {
  repair: 'Ремонт',
  washing: 'Мойка',
  pass: 'Пропуски',
  osago: 'ОСАГО',
  gpmVehicleInspection: 'ГПМ',
  inspection: 'Тех. осмотр',
};

export const serviceOrderTypeEnum = {
  repair: 'repair',
  washing: 'washing',
  pass: 'pass',
  osago: 'osago',
  gpmVehicleInspection: 'gpmVehicleInspection',
  inspection: 'inspection',
};

export const washingStatusTypes = {
  scheduled: 'Выдан',
  done: 'Выполнено',
};

export const washingStatusEnum = {
  scheduled: 'scheduled',
  done: 'done',
};

export const washingTypeEnum = {
  body: 'body',
  interior: 'interior',
  engine: 'engine',
};

export const washingTypes = {
  body: 'Кузов',
  interior: 'Салон',
  engine: 'ДВС',
};

export const defectiveStatementStatusTypes = {
  created: 'Создана',
  approvement: 'На согласовании',
  approved: 'Согласована',
  declined: 'Отклонена',
};

export const defectiveStatementStatusEnum = {
  created: 'created',
  approvement: 'approvement',
  approved: 'approved',
  declined: 'declined',
};

export const maintenanceStatusesTypes = {
  draft: 'Черновик',
  inProgress: 'В работе',
  approved: 'Утвержден',
};

export const maintenanceStatusesEnum = {
  draft: 'draft',
  inProgress: 'inProgress',
  approved: 'approved',
};

export const maintenanceScheduleStatusesTypes = {
  ...maintenanceStatusesTypes,
  approved: 'Выполнено',
};

export const orderContractorTypes = {
  internal: 'Хоз. способ',
  contractor: 'Подрядчик',
};

export const orderContractorTypeEnum = {
  internal: 'internal',
  contractor: 'contractor',
};

export const operationType = {
  ...orderContractorTypes,
};

export const operationTypeEnum = {
  ...orderContractorTypeEnum,
};

export const vehicleIssueTypes = {
  tinWorks: 'Жестяные работы',
  weldingWorks: 'Сварочные работы',
  straightening: 'Выравнивание',
};

export const vehicleIssueTypeEnum = {
  tinWorks: 'tinWorks',
  weldingWorks: 'weldingWorks',
  straightening: 'straightening',
};

export const maintenanceOperationStatuses = {
  proposal: 'В ожидании',
  approved: 'Подтвержден',
  declined: 'Отклонен',
};

export type MaintenanceOperationStatus = $Keys<
  typeof maintenanceOperationStatuses
>;

export const monitoringVehicleStatusesEnum = {
  active: 'active',
  nosignal: 'nosignal',
  nonactive: 'nonactive',
};

/**
 * Статус ТС в мониторинге
 */
export const monitoringVehicleStatuses = {
  active: 'Активный',
  nosignal: 'Нет сигнала',
  nonactive: 'Не активный',
};

export const monitoringVehicleStatesEnum = {
  stopped: 'stopped',
  inmove: 'inmove',
  parking: 'parking',
  undef: 'undefined',
};

/**
 * Состояние ТС в мониторинге
 */
export const monitoringVehicleStates = {
  stopped: 'Стоит',
  inmove: 'В движении',
  parking: 'На парковке',
  undef: 'Неизвестно',
};

export const briefingViews = {
  primary: 'Первичный',
  inWorkingPlace: 'На рабочем месте',
  repeat: 'Повторный',
  unscheduled: 'Внеплановый',
};

export const briefingViewEnum = {
  primary: 'primary',
  inWorkingPlace: 'inWorkingPlace',
  repeat: 'repeat',
  unscheduled: 'unscheduled',
};

export const trainingViews = {
  primary: 'Первичный',
  inWorkingPlace: 'На рабочем месте',
  repeat: 'Повторный',
  unscheduled: 'Внеплановый',
};

export const trainingViewEnum = {
  primary: 'primary',
  inWorkingPlace: 'inWorkingPlace',
  repeat: 'repeat',
  unscheduled: 'unscheduled',
};

export const certificationViews = {
  primary: 'Первичный',
  inWorkingPlace: 'На рабочем месте',
  repeat: 'Повторный',
  unscheduled: 'Внеплановый',
};

export const certificationViewEnum = {
  primary: 'primary',
  inWorkingPlace: 'inWorkingPlace',
  repeat: 'repeat',
  unscheduled: 'unscheduled',
};

export const employeeCertificationViews = {
  primary: 'Первичный',
  inWorkingPlace: 'На рабочем месте',
  repeat: 'Повторный',
  unscheduled: 'Внеплановый',
};

export const employeeCertificationViewEnum = {
  primary: 'primary',
  inWorkingPlace: 'inWorkingPlace',
  repeat: 'repeat',
  unscheduled: 'unscheduled',
};

export const loadUnloadTypes = {
  load: 'Погрузка',
  unload: 'Выгрузка',
};

export const loadUnloadTypeEnum = {
  load: 'load',
  unload: 'unload',
};

// Тип ТС для ОВБ (оперативной выездной бригады)
export const vehicleOvbTypes = {
  forEightHour: '8 ч', // выделение на 8 чаcов
  forTwelveHour: '12 ч', // выделение на 12 чаcов
  forWholeDay: '24 ч', // выделение на целый день (24 ч)
};

// Типы событий для топливной карты
export const fuelCardVehicleTypes = {
  installed: 'Привязка',
  removed: 'Отвязка',
};

export const ownerTypes = {
  self: 'self',
  contract: 'contract',
};

// Статусы запроса НТС
export const contractVehicleRequestStatusesEnum = {
  created: 'created',
  reviewing: 'reviewing',
  approved: 'approved',
  declined: 'declined',
};

export const contractVehicleRequestStatuses = {
  created: 'Создана',
  reviewing: 'На рассмотрении',
  approved: 'Подтверждена',
  declined: 'Отклонена',
};

export const drivingLicenseCategories = {
  a: 'A',
  b: 'B',
  c: 'C',
  d: 'D',
  e: 'E',
  m: 'M',
};

export const drivingLicenseCategoryEnum = {
  a: 'a',
  b: 'b',
  c: 'c',
  d: 'd',
  e: 'e',
  m: 'm',
};

export const tractorDrivingLicenseCategories = {
  a11Tractor: 'A1 (I)',
  a22Tractor: 'A2 (II)',
  a33Tractor: 'A3 (III)',
  a34Tractor: 'A3 (IV)',
  bTractor: 'B',
  cTractor: 'C',
  dTractor: 'D',
  eTractor: 'E',
  fTractor: 'F',
};

export const tractorDrivingLicenseCategoryEnum = {
  a11Tractor: 'a11Tractor',
  a22Tractor: 'a22Tractor',
  a33Tractor: 'a33Tractor',
  a34Tractor: 'a34Tractor',
  bTractor: 'bTractor',
  cTractor: 'cTractor',
  dTractor: 'dTractor',
  eTractor: 'eTractor',
  fTractor: 'fTractor',
};

export const chassisConfigurations = {
  fourToTwo: '4х2',
  fourToFour: '4х4',
  sixToFour: '6х4',
  sixToSix: '6х6',
  eightToFour: '8х4',
  eightToSix: '8х6',
  eightToEight: '8х8',
};

export const nodeFilterTypeEnum = {
  onlySelf: 'onlySelf', // Только себя
  branchAndChildren: 'branchAndChildren', // Вышележащий филиал и нижележащие службы
  selfAndChildren: 'selfAndChildren', // Текущий филиал и нижележащие службы
  onlyBranch: 'onlyBranch', // Только филиал
};

export const tripTypeEnum = {
  income: 'income',
  archive: 'archive',
  contract: 'contract',
};

export const serviceOrderStatusEnum = {
  pending: 'pending',
  completed: 'completed',
  canceled: 'canceled',
};

export const serviceOrderStatuses = {
  pending: 'Ожидание',
  completed: 'Завершено',
  canceled: 'Отменено',
};

export const engineTypes = {
  diesel: 'Дизельный',
  petrol: 'Бензиновый',
  methane: 'Метановый',
};

export const engineTypeEnum = {
  diesel: 'diesel',
  petrol: 'petrol',
  methane: 'methane',
};

// Теги для файлов
export const fileTags = {
  mainDocs: 'Основные документы',
  all: 'Файлы',
  osago: 'Полисы Страхования',
  repairActs: 'Акты ремонтов',
  driverPhoto: 'Фото водителя',
  pddPhoto: 'Фото ПДД',
};

export const fileTagEnum = {
  all: 'all',
  mainDocs: 'mainDocs',
  osago: 'osago',
  repairActs: 'repairActs',
  driverPhoto: 'driverPhoto',
  pddPhoto: 'pddPhoto',
};

// Типы работ для видов ремонта
export const maintenanceTypeOfWork = {
  akb: 'Акб',
  tire: 'Шины',
  common: 'Обычный',
  to1: 'ТО-1',
  to2: 'ТО-2',
  to3: 'ТО-3',
  seasonMaintenance: 'Сезонное ТО',
  maintenance: 'Ремонт',
  changeOil: 'Замена масла',
};

export const maintenanceTypeOfWorkEnum = {
  akb: 'akb',
  tire: 'tire',
  common: 'common',
  to1: 'to1',
  to2: 'to2',
  to3: 'to3',
  seasonMaintenance: 'seasonMaintenance',
  maintenance: 'maintenance',
  changeOil: 'changeOil',
};

// Теги для файлов ремонтов

export const maintenanceFileTags = {
  replacementTire: 'Замена шин',
  replacementAkb: 'Замена АКБ',
};

export const maintenanceFileTagEnum = {
  replacementTire: 'replacementTire',
  replacementAkb: 'replacementAkb',
};

export const operationLimitTypes = {
  employee: 'Должность',
  orgUnit: 'Служба',
  ovb: 'ОВБ',
};

export const operationLimitTypeEnum = {
  employee: 'employee', // Должностные лица
  orgUnit: 'orgUnit', // Службы
  ovb: 'ovb', // ОВБ
};

// Агрегируемые поля лимита
export const operationLimitAggregationFieldsEnum = {
  hours: 'hours',
  hoursWeekend: 'hoursWeekend',
  distance: 'distance',
  distanceWeekend: 'distanceWeekend',
};

export const monthlyWashingPlanStatuses = {
  draft: 'Черновик',
  approvement: 'На согласовании',
  inWork: 'В работе',
};

export const monthlyWashingPlanStatusEnum = {
  draft: 'draft',
  approvement: 'approvement',
  inWork: 'inWork',
};

export const certificationAreas = {
  a1: 'A.1',
  b931: 'Б.9.31',
  b932: 'Б.9.32',
};

export const certificationAreaEnum = {
  a1: 'a1',
  b931: 'b931',
  b932: 'b932',
};

// Кварталы
export const quartersEnum = {
  first: ['январь', 'февраль', 'март'],
  second: ['апрель', 'май', 'июнь'],
  third: ['июль', 'август', 'сентябрь'],
  fourth: ['октябрь', 'ноябрь', 'декабрь'],
};

export const quarters = {
  first: '1-й квартал',
  second: '2-й квартал',
  third: '3-й квартал',
  fourth: '4-й квартал',
};

export const operationLimitGroupStatusEnum = {
  // Черновик
  draft: 'draft',
  // На согласовании
  onAgreeing: 'onAgreeing',
  // Утвержден
  approved: 'approved',
};

export const operationLimitGroupStatuses = {
  draft: 'Черновик',
  onAgreeing: 'На согласовании',
  approved: 'Утвержден',
};

/**
 * Статусы расчетов полисов ОСАГО
 */
export const osagoCalculationStatusEnum = {
  draft: 'draft',
  onApproving: 'onApproving',
  approved: 'approved',
};

export const osagoCalculationStatuses = {
  draft: 'Черновик',
  onApproving: 'На утверждении',
  approved: 'Утвержден',
};

export const contractTripStatusEnum = {
  contractorReview: 'contractorReview',
  verified: 'verified',
  accepted: 'accepted',
  canceled: 'canceled',
};

export const contractTripStatuses = {
  contractorReview: 'На рассмотрении',
  verified: 'Согласован',
  accepted: 'Принят',
  canceled: 'Отменен',
};

export const regulationLimitTypes = {
  employee: 'Должность',
  orgUnit: 'Служба',
  ovb: 'ОВБ',
  oneOffRequests: 'Разовые заявки',
};

export const regulationLimitTypeEnum = {
  employee: 'employee', // Должностные лица
  orgUnit: 'orgUnit', // Службы
  ovb: 'ovb', // ОВБ
  oneOffRequests: 'oneOffRequests', // Разовые заявки
};

export const expenseDirectionTypes = {
  vehicleServicesFromProfit: 'Транспортные расходы из прибыли',
  otherServices: 'Прочие услуги',
  carServices: 'Услуги легкового транспорта',
  otherVehicleServices: 'Прочие транспортные расходы',
  acsVehicleServices: 'Услуги автотранспорта для ОКС',
  cargoServices: 'Услуги а/т со стороны по транспортировке грузов',
  mechanismServices: 'Услуги механизмов',
  provisionVehicleSecvices: 'Транспортно заготовительные расходы',
  battery: 'Потребность в АКБ',
  expertise: 'Потребность в экспертизе и ТО ПБ ГПМ',
  gps: 'Потребность в GPS-Навигации',
  measuringDeviceCertification: 'Потребность в тарировке цистерн',
  gtnTax: 'Потребность в ТО Гостехнадзор (пошлина)',
  gtnWork: 'Потребность в ТО Гостехнадзор (работа)',
  gibdd: 'Потребность в ТО ГИБДД',
  pass: 'Пропуска',
  passTax: 'Пропуска (госпошлина)',
  licensePlateChange: 'Потребность в замене гос.номеров',
  materials: 'Потребность в инструментах, материалах',
  federalHighway: 'Плата за проезд по федеральным трассам (ПЛАТОН)',
  washing: 'Потребность в мойке',
  driverInspection: 'Потребность в предрейсовых/послерейсовых осмотрах',
  // vehiclePlan: 'План работы СТС',
  // repair: 'Ремонт СТС',
  // fuelsAndOils: 'Потребность в ГСМ',
  tires: 'Потребность в автошинах',
  maintenance: 'Потребность в тех.обслуживании',
  aviation: 'Авиационные услуги',
  helicopter: 'Услуги вертолета',
  notarial: 'Нотариальные действия',
  petrol: 'Потребность в бензине (АИ-80,92,95)',
  currentRepair: 'Текущий ремонт СТС',
  overhaul: 'Капитальный ремонт СТС',
  dieselFuel: 'Потребность в ДТ',
  vehicleTax: 'Транспортный налог',
  osago: 'Страхование ТС',
  oilsAndGreases: 'Потребность в маслах и смазках',
};

export const expenseDirectionTypeEnum = {
  vehicleServicesFromProfit: 'vehicleServicesFromProfit',
  otherServices: 'otherServices',
  carServices: 'carServices',
  otherVehicleServices: 'otherVehicleServices',
  acsVehicleServices: 'acsVehicleServices',
  cargoServices: 'cargoServices',
  mechanismServices: 'mechanismServices',
  provisionVehicleSecvices: 'provisionVehicleSecvices',
  battery: 'battery',
  expertise: 'expertise',
  gps: 'gps',
  measuringDeviceCertification: 'measuringDeviceCertification',
  gtnTax: 'gtnTax',
  gtnWork: 'gtnWork',
  gibdd: 'gibdd',
  pass: 'pass',
  passTax: 'passTax',
  licensePlateChange: 'licensePlateChange',
  materials: 'materials',
  federalHighway: 'federalHighway',
  washing: 'washing',
  driverInspection: 'driverInspection',
  // vehiclePlan: 'vehiclePlan',
  // repair: 'repair',
  // fuelsAndOils: 'fuelsAndOils',
  tires: 'tires',
  maintenance: 'maintenance',
  aviation: 'aviation',
  helicopter: 'helicopter',
  notarial: 'notarial',
  petrol: 'petrol',
  currentRepair: 'currentRepair',
  overhaul: 'overhaul',
  dieselFuel: 'dieselFuel',
  vehicleTax: 'vehicleTax',
  osago: 'osago',
  oilsAndGreases: 'oilsAndGreases',
};

export const workHourPriceEnum = {
  contractMethod: 'contractMethod',
  contractMethodOnTheGo: 'contractMethodOnTheGo',
  economicMethod: 'economicMethod',
};

export const workHourPriceTypes = {
  contractMethod: 'Подрядный метод',
  contractMethodOnTheGo: 'Подрядный метод на выезде',
  economicMethod: 'Хоз. способ',
};

export const STSEnum = {
  exploited: 'exploited',
  forBudget: 'forBudget',
};

export const STSTypes = {
  exploited: 'В эксплутации',
  forBudget: 'Для бюджета',
};

export const orderDispatcherTypes = {
  all: 'Все заявки',
  self: 'Мои заявки',
};

export const approveEnum = {
  approved: 'approved',
  notApproved: 'notApproved',
};

export const approveTypes = {
  approved: 'Утвержден',
  notApproved: 'Не утвержден',
};

export const pseudoNameEnum = {
  main: 'main',
  approvedOnBk: 'approvedOnBk',
  approvedOnBkf: 'approvedOnBkf',
};

export const pseudoNameTypes = {
  main: 'Основной',
  approvedOnBk: 'Утвержденный на БК',
  approvedOnBkf: 'Утвержденный на БКФ',
};

export const monthsEnum = {
  january: 'january',
  february: 'february',
  march: 'march',
  april: 'april',
  may: 'may',
  june: 'june',
  july: 'july',
  august: 'august',
  september: 'september',
  october: 'october',
  november: 'november',
  december: 'december',
};

export const monthsTypes = {
  january: 'Январь',
  february: 'Февраль',
  march: 'Март',
  april: 'Апрель',
  may: 'Май',
  june: 'Июнь',
  july: 'Июль',
  august: 'Август',
  september: 'Сентябрь',
  october: 'Октябрь',
  november: 'Ноябрь',
  december: 'Декабрь',
};

export const technicalFluidTypesEnum = {
  oil: 'oil',
  antifreeze: 'antifreeze',
  windshieldWasherFluid: 'windshieldWasherFluid',
  brakeFluid: 'brakeFluid',
};

export const technicalFluidTypes = {
  oil: 'Масло',
  antifreeze: 'Антифриз',
  windshieldWasherFluid: 'ЖБО',
  brakeFluid: 'Тормозная жидкость',
};

export const technicalFluidStatusEnum = {
  approved: 'approved',
  draft: 'draft',
};

export const technicalFluidStatusTypes = {
  approved: 'Утвержден',
  draft: 'Черновик',
};

export const sparePartMaterialTypesEnum = {
  ...technicalFluidTypesEnum,
  spare: 'spare',
};

export const sparePartMaterialTypes = {
  ...technicalFluidTypes,
  spare: 'Зап. часть',
};

export const pddTestStatuses = {
  passed: 'Сдан',
  notPassed: 'Не сдан',
};

export const pddTestStatusesEnum = {
  passed: 'passed',
  notPassed: 'notPassed',
};

export const pddTicketStatuses = {
  draft: 'draft',
  done: 'done',
};

export const pddTicketStatusesEnum = {
  draft: 'Черновик',
  done: 'Готов',
};

export const pddTest = {
  exam: 'exam',
  training: 'training',
};

export const pddTestEnum = {
  exam: 'Экзамен',
  training: 'Обучение',
};

export const budgetSummaryStatusEnum = {
  created: 'created',
  approvement: 'approvement',
  approved: 'approved',
  declined: 'declined',
};

export const budgetSummaryStatus = {
  created: 'Черновик',
  approvement: 'На утверждении',
  approved: 'Утвержден',
  declined: 'Отклонен',
};

export const budgetSummaryEnum = {
  main: 'main',
  clarification: 'clarification',
};

export const budgetSummary = {
  main: 'Основной',
  clarification: 'Уточнение',
};

export const budgetLineItemBudgetEnum = {
  costFromProfit: 'costFromProfit',
  estimate: 'estimate',
  repair: 'repair',
  stocksAndPurchases: 'stocksAndPurchases',
};

export const budgetLineItemBudget = {
  costFromProfit: 'Расходы из прибыли',
  estimate: 'Смета затрат',
  repair: 'Расходы на ремонт(СМКТР)',
  stocksAndPurchases: 'Бюджет запасов и покупок',
};
// Необходимо для склонения "Итого по "
export const budgetLineItemBudgetInline = {
  costFromProfit: 'расходам из прибыли',
  estimate: 'смете затрат',
  repair: 'расходам на ремонт(СМКТР)',
  stocksAndPurchases: 'бюджету запасов и покупок',
};

export const branchBudgetSummaryExpenseDirectionType = {
  common: 'Обычное',
  estimate: 'Расчетное',
  display: 'Для отображения',
};

export const branchBudgetSummaryExpenseDirectionTypeEnum = {
  common: 'common',
  estimate: 'estimate',
  display: 'display',
};

export const cognosContractStatus = {
  ...entityStatus,
};

export const cognosContractStatusEnum = {
  ...entityStatusEnum,
};

export const sideServiceType = {
  wasteDump: 'Сброс отходов',
  providingEquipment: 'Предоставление техники',
};
export const sideServiceTypeEnum = {
  wasteDump: 'wasteDump',
  providingEquipment: 'providingEquipment',
};

export const sideServiceTariffType = {
  common: 'Стандартный',
  preferential: 'Льготный',
};

export const sideServiceTariffTypeEnum = {
  common: 'common',
  preferential: 'preferential',
};

// Единица учета тарифа услуги на сторону.
export const sideServiceTariffMeasure = {
  hour: 'Час',
  cbm: 'Кубический метр',
};
export const sideServiceTariffMeasureEnum = {
  hour: 'hour',
  cbm: 'cbm',
};

//
export const notificationType = {
  bioTreatmentFacilityFact: 'Журнал въезда БОС',
  expiringCoupons: 'Уведомление о заканчивающихся купонах',
};
export const notificationTypeEnum = {
  bioTreatmentFacilityFact: 'bioTreatmentFacilityFact',
  expiringCoupons: 'expiringCoupons',
};
