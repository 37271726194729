// @flow

import React, { type Node, Component } from 'react';
import AntDropdown from 'antd/lib/dropdown';

export type DropdownType = {
  onVisibleChange: (flag: boolean) => void
};

type Props = {
  overlay: Node,
  children: any,
  overlayStyle?: any
};

type State = {
  visible: boolean
};

/**
 * Выпадающий список с возможностью самостоятельно
 * указывать, должен список сейчас быть открытым или нет
 */
export default class Dropdown extends Component<Props, State> {
  state = {
    visible: false
  };

  /**
   * Функция, меняющая состояние (открыто/закрыто)
   * Можно вызывать через ссылку на инстанс компоненты
   */
  onVisibleChange = (flag: boolean) => {
    this.setState({
      visible: flag
    });
  };

  render() {
    const { visible } = this.state;
    const { overlay, children, overlayStyle, placement } = this.props;
    return (
      <AntDropdown
        trigger={['click']}
        visible={visible}
        overlay={overlay}
        overlayStyle={overlayStyle}
        onVisibleChange={this.onVisibleChange}
      >
        {children}
      </AntDropdown>
    );
  }
}
