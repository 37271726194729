// @flow
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, notification } from 'antd';

import CommonCardPage from '../../../components/hoc/common/handbook/CardPage';
import Section, { SectionTitle } from '../../../components/layout/Section';
import { notificationLoading } from '../../../components/Notifications';
import { ButtonsRow } from '../../../components/ui';
import { branchBudgetSummaryApi, budgetSummaryApi } from '../../../lib/api';
import { getBreadCrumbsByUrlForEdit } from '../../../lib/autoBreadcrumbs';
import { accessTypeEnum, budgetSummaryStatusEnum } from '../../../lib/enum';

import { navigate } from '../../../lib/helpers';
import {
  changeNotification,
  saveNotification,
} from '../../../lib/notificationWrapper';
import type {
  BranchBudgetSummary,
  BudgetSummary,
  BudgetSummaryStatusType,
  UserAccess,
} from '../../../lib/types';

import {
  approveDetailedBudgetAccessRight,
  editDetailedBudgetAccessRight,
  viewDetailedBudgetAccessRight,
} from '../details/accessRight';

import { withUserAccess } from './../../withUserAccess';

import { BranchForm, DeclineReasonModal, InnerForm, Tabs } from './components';

type PageProps = {
  id: number,
  orgUnitId?: number,
  userAccess: UserAccess[],
  type?: string,
};

const entryPointPath = '/budget/summary/';

const nextStatusResolver = {
  [budgetSummaryStatusEnum.created]: [budgetSummaryStatusEnum.approvement],
  [budgetSummaryStatusEnum.declined]: [budgetSummaryStatusEnum.approvement],
  [budgetSummaryStatusEnum.approvement]: [
    budgetSummaryStatusEnum.approved,
    budgetSummaryStatusEnum.declined,
  ],
  [budgetSummaryStatusEnum.approved]: [budgetSummaryStatusEnum.declined],
};
const nameResolver = {
  [budgetSummaryStatusEnum.created]: 'На согласование',
  [budgetSummaryStatusEnum.declined]: 'Отклонить',
  [budgetSummaryStatusEnum.approvement]: 'На согласование',
  [budgetSummaryStatusEnum.approved]: 'Согласовать',
};

export default withUserAccess((props: PageProps) => {
  const [data: BudgetSummary, setData] = useState({
    status: budgetSummaryStatusEnum.created,
    id: 0,
  });
  const [branchesData: BranchBudgetSummary[], setBranchesData] = useState([]);

  const [branchData: BranchBudgetSummary, setBranchData] = useState({
    id: 0,
    status: budgetSummaryStatusEnum.created,
    contractVehiclePlanId: 0,
    budgetSummaryId: 0,
  });

  const [declineReasonModalVisible, setDeclineReasonModalVisible] =
    useState(false);

  const breadCrumbs = useMemo(
    () => getBreadCrumbsByUrlForEdit(entryPointPath, props.id),
    [props.id]
  );

  const canApprove = useCallback(() =>
    props.userAccess.some((access) =>
      approveDetailedBudgetAccessRight.includes(access)
    )
  );

  const onFetch = useCallback(async (id?: number) => {
    !!id && setData(await budgetSummaryApi.get(id));
  }, []);

  const onSubmit = useCallback(async (payload: BudgetSummary) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
        duration: 2,
      });
      let id = payload.id;
      if (id) {
        await budgetSummaryApi.update(payload);
      } else {
        let data = await budgetSummaryApi.add(payload);
        id = data.id;
      }
      await navigate(`${entryPointPath}${id}/-1`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  }, []);

  const onFetchBranches = useCallback(
    async (budgetSummaryId?: number) => {
      if (!budgetSummaryId) return;

      try {
        let { data } = await branchBudgetSummaryApi.fetch({ budgetSummaryId });
        data = canApprove()
          ? [
              ...data,
              {
                orgUnitId: -1,
                id: -1,
                orgUnitName: 'Свод',
              },
            ]
          : [...data];
        setBranchesData(
          data.sort((a, b) => {
            return a.orgUnitMnemonic > b.orgUnitMnemonic ? 1 : -1;
          })
        );
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      } finally {
        notification.close('saving');
      }
    },
    [canApprove]
  );

  const onFetchBranch = useCallback(async (id?: number) => {
    if (!id) return;
    try {
      const data = await branchBudgetSummaryApi.get(id);
      setBranchData(data);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  }, []);

  const onFetchSummary = useCallback(async (id?: number) => {
    if (!id) return;
    try {
      let data = await budgetSummaryApi.getExpenseDirections(id);
      data = {
        budgetSummaryLineItems: data,
        orgUnitId: -1,
        id: -1,
        orgUnitName: 'Свод',
        budgetSummaryId: 0,
        status: budgetSummaryStatusEnum.created,
      };
      setBranchData(data);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  }, []);

  const onSubmitBranch = useCallback(async (payload: BranchBudgetSummary) => {
    await saveNotification(async () => {
      const data = await branchBudgetSummaryApi.update(payload);
      setBranchData(data);
    });
  }, []);

  useEffect(() => {
    async function fetcher() {
      await Promise.all([onFetch(props.id), onFetchBranches(props.id)]);
    }
    fetcher().then();
  }, [onFetch, onFetchBranches, props.id]);

  const changeStatus = useCallback(
    async (params: { status: BudgetSummaryStatusType, id: number }) => {
      await changeNotification(async () => {
        await budgetSummaryApi.changeStatus(params);
        setData({ ...data, status: params.status });
      });
    },
    [data]
  );

  const changeBranchStatus = useCallback(
    async (data: any) => {
      await changeNotification(async () => {
        await branchBudgetSummaryApi.changeStatus(data);
        setBranchData({ ...branchData, status: data.status });
        await onFetchBranches(branchData.budgetSummaryId);
      });
    },
    [branchData, onFetchBranches]
  );

  useEffect(() => {
    if (+props.orgUnitId > 0) {
      onFetchBranch(
        branchesData.find((el) => el.orgUnitId === +props.orgUnitId)?.id
      );
    } else if (+props.orgUnitId === -1) {
      onFetchSummary(props.id);
    }
  }, [props.id, onFetchBranch, onFetchSummary, props.orgUnitId, branchesData]);

  const statusChangeButton = useCallback(() => {
    const allow =
      !!branchesData.length &&
      branchesData.every(
        (el: BranchBudgetSummary) =>
          el.status === budgetSummaryStatusEnum.approved
      );
    return (
      (data.status === budgetSummaryStatusEnum.approvement ||
        data.status === budgetSummaryStatusEnum.approved ||
        allow) && (
        <ButtonsRow>
          {nextStatusResolver[data.status]?.map((nextStatus) => (
            <Button
              type="primary"
              onClick={() =>
                changeStatus({
                  status: nextStatus,
                  id: data.id,
                })
              }
            >
              {nameResolver[nextStatus]}
            </Button>
          ))}
        </ButtonsRow>
      )
    );
  }, [branchesData, changeStatus, data.id, data.status]);

  const branchStatusChangeButton = useCallback(() => {
    if (
      (branchData.id > 0 &&
        !!branchData.contractVehiclePlanId &&
        (data.status === budgetSummaryStatusEnum.created ||
          data.status === budgetSummaryStatusEnum.declined) &&
        branchData.status !== budgetSummaryStatusEnum.approved &&
        canApprove()) ||
      (branchData.status === budgetSummaryStatusEnum.approvement &&
        props.userAccess.includes(accessTypeEnum.approvingDetailedBudget))
    ) {
      return (
        <ButtonsRow>
          {nextStatusResolver[branchData.status]?.map((nextStatus) => (
            <Button
              type="primary"
              onClick={() => {
                if (nextStatus === budgetSummaryStatusEnum.declined) {
                  setDeclineReasonModalVisible(true);
                } else {
                  changeBranchStatus({
                    status: nextStatus,
                    id: branchData.id,
                  });
                }
              }}
            >
              {nameResolver[nextStatus]}
            </Button>
          ))}
        </ButtonsRow>
      );
    }
    if (
      branchData.id === -1 &&
      data.status === budgetSummaryStatusEnum.created &&
      canApprove()
    ) {
      return (
        <Button
          type="primary"
          onClick={() =>
            changeStatus({
              status: budgetSummaryStatusEnum.approved,
              id: data.id,
            })
          }
        >
          Утвердить
        </Button>
      );
    }
  }, [
    branchData.contractVehiclePlanId,
    branchData.id,
    branchData.status,
    canApprove,
    changeBranchStatus,
    changeStatus,
    data.id,
    data.status,
    props.userAccess,
  ]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Основной бюджет',
        rightHeader: <>{statusChangeButton()}</>,
      }}
    >
      <Fragment noWrapMe>
        <InnerForm data={data} onFetch={onFetch} onSubmit={onSubmit} />

        <Section>
          {!!data.id && (
            <>
              <SectionTitle divider suffix={branchStatusChangeButton()}>
                <Tabs branchData={branchesData} id={props.id} />
              </SectionTitle>
              <BranchForm
                orgUnitId={props.orgUnitId}
                branchBudgetSummary={branchData}
                budgetSummary={data}
                onSubmit={onSubmitBranch}
                type={props.type}
              />
            </>
          )}
        </Section>
      </Fragment>
      <DeclineReasonModal
        noWrapMe
        visible={declineReasonModalVisible}
        handleOk={async ({ declineReason }) => {
          setDeclineReasonModalVisible(false);
          await Promise.all([
            changeBranchStatus({
              status: budgetSummaryStatusEnum.declined,
              id: branchData.id,
            }),
            onSubmitBranch({
              ...branchData,
              declineReason,
              status: budgetSummaryStatusEnum.declined,
            }),
          ]);
        }}
        handleCancel={() => {
          setDeclineReasonModalVisible(false);
        }}
      />
    </CommonCardPage>
  );
});
