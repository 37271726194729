// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';

import { Card } from './../../components';
import type {
  Maintenance,
  VehicleIssue,
  DetailedDefectiveStatement,
} from './../../lib/types';
import {
  vehicleTypes,
  maintenanceStatusesTypes,
  repairPlanTypes,
  orderContractorTypes,
} from './../../lib/enum';
import { Panel, Section } from './../../components/layout';
import Grid, { GridItem } from './../../components/layout/Grid';
import Header from '../../components/layout/Header';
import {
  applyMaskToValue,
  convertContractorToString,
  formatDateTimeToString,
  showWorkEngineHours,
  toLocalStringRu,
} from './../../lib/helpers';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { Dropdown } from '../../components/ui';
import { SectionTitle } from '../../components/layout';
import { VehicleIssuesReadOnly } from './VehicleIssues';
import {
  DeclineDetailedDefectiveStatementSelect,
  OperationsReadOnly,
  PartsAndConsumablesReadOnly,
} from './DetailedDefectiveStatememt/components';
import { notificationLoading } from './../../components/Notifications';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';
import {
  maintenanceApi,
  detailedDefectiveStatementApi,
  vehicleIssueApi,
} from '../../lib/api';

const { Field } = Card;

const StyledPanel = styled(Panel)`
  padding-top: 0;
  & > h1 {
    margin-bottom: 16px;
  }
`;
const Content = styled.div`
  padding: 16px;
`;
const ContentTitle = styled.h2`
  margin: 16px 0;
  font-weight: 500;
`;
const StyledButtonDiv = styled.div`
  button {
    margin-left: 10px;
  }
`;

type Props = {
  maintenanceId: number,
};

type State = {
  maintenance: ?Maintenance,
  vehicleIssues: VehicleIssue[],
  // выбран просмотр отклонненной детальной дефектной ведомости
  isDeclinedDetailedDefectiveStatement: boolean,
  // отклоненные детальные дефектные ведомости
  declinedDetailedDefectiveStatement: ?(DetailedDefectiveStatement[]),
  // актуалная дефектная веломость
  detailedDefectiveStatement: ?DetailedDefectiveStatement,
};

export class MaintenanceCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDeclinedDetailedDefectiveStatement: false,
      maintenance: null,
      vehicleIssues: [],
      declinedDetailedDefectiveStatement: [],
      detailedDefectiveStatement: null,
    };
  }

  getDetailedDefectiveStatement = async (id?: number) => {
    const { maintenance } = this.state;
    let result = {
      detailedDefectiveStatement: null,
      declinedDetailedDefectiveStatement: [],
    };
    const maintenanceId = id ? id : maintenance ? maintenance.id : null;
    if (maintenanceId) {
      const declined =
        await detailedDefectiveStatementApi.fetchDetailedDefectiveStatement({
          maintenanceId,
          returnDeleted: true,
          isDeleted: true,
        });
      result = {
        detailedDefectiveStatement:
          await detailedDefectiveStatementApi.getActualDetailedDefectiveStatement(
            maintenanceId
          ),
        declinedDetailedDefectiveStatement: declined.data,
      };
    }
    return result;
  };

  async componentDidMount() {
    const { maintenanceId } = this.props;
    if (maintenanceId) {
      try {
        const maintenance = await maintenanceApi.fetchMaintenance(
          maintenanceId
        );
        const {
          detailedDefectiveStatement,
          declinedDetailedDefectiveStatement,
        } = await this.getDetailedDefectiveStatement(maintenanceId);
        this.setState({
          maintenance,
          vehicleIssues: maintenance.vehicleIssues,
          detailedDefectiveStatement,
          declinedDetailedDefectiveStatement:
            declinedDetailedDefectiveStatement,
        });
      } catch (err) {
        notification.error({
          message: 'Ошибка',
          description: err.message,
        });
      }
    }
  }

  onChangeDeclineDetailedDefectiveStatement = async (
    value: any,
    option: any
  ) => {
    const { maintenance } = this.state;
    if (value === undefined && maintenance) {
      const detailedDefectiveStatement =
        await detailedDefectiveStatementApi.getActualDetailedDefectiveStatement(
          maintenance.id
        );
      this.setState({
        detailedDefectiveStatement,
        isDeclinedDetailedDefectiveStatement: false,
      });
    } else {
      const { data } = option.props;
      this.setState({
        detailedDefectiveStatement: data,
        isDeclinedDetailedDefectiveStatement: true,
      });
    }
  };

  handlePrint = async ({ key, vehicleIssueId }: any) => {
    const { detailedDefectiveStatement, maintenance } = this.state;
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      if (detailedDefectiveStatement && maintenance) {
        switch (key) {
          case 'print':
            await detailedDefectiveStatementApi.printDetailedDefectiveStatement(
              detailedDefectiveStatement.id
            );
            break;
          case 'printOs3':
            await detailedDefectiveStatementApi.printOs3FormDetailedDefectiveStatement(
              detailedDefectiveStatement.id
            );
            break;
          case 'printWriteOffAct':
            await detailedDefectiveStatementApi.printWriteOffActDetailedDefectiveStatement(
              detailedDefectiveStatement.id
            );
            break;
          case 'printMaintenance':
            await maintenanceApi.printMaintenance(maintenance.id);
            break;
          case 'printVehicleIssue':
            await vehicleIssueApi.printVehicleIssue(vehicleIssueId);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  render() {
    const {
      maintenance,
      vehicleIssues,
      detailedDefectiveStatement,
      declinedDetailedDefectiveStatement,
      isDeclinedDetailedDefectiveStatement,
    } = this.state;
    if (maintenance === null) {
      return null;
    }
    console.log(maintenance, detailedDefectiveStatement);
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Транспорт</Crumb>
              <Crumb to="/maintenances/journal">Ремонт</Crumb>
              {maintenance && <Crumb>Карточка работ №{maintenance.id}</Crumb>}
            </Breadcrumbs>
          }
          right={
            <StyledButtonDiv>
              {maintenance && (
                <Button onClick={() => this.handlePrint('printMaintenance')}>
                  Печать
                </Button>
              )}
            </StyledButtonDiv>
          }
        />
        <StyledPanel>
          <h1> {maintenance && <>Карточка работ №{maintenance.id}</>}</h1>
          {maintenance && (
            <Grid gutter="16px" cols={6}>
              <GridItem>
                <Field label="Статус">
                  {maintenance.status
                    ? maintenanceStatusesTypes[maintenance.status]
                    : ''}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип">
                  {maintenance.maintenanceWorkType.name}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="График работ">
                  {repairPlanTypes[maintenance.repairPlan]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Способ выполнения">
                  {orderContractorTypes[maintenance.orderContractorType]}
                </Field>
              </GridItem>
              {maintenance.executor && (
                <GridItem>
                  <Field label="Исполнитель">{maintenance.executor.name}</Field>
                </GridItem>
              )}
              {maintenance.contractor && (
                <GridItem>
                  <Field label="Подрядчик">
                    {convertContractorToString(maintenance.contractor)}
                  </Field>
                </GridItem>
              )}
              {maintenance.contractNumber && (
                <GridItem>
                  <Field label="Договор">{maintenance.contractNumber}</Field>
                </GridItem>
              )}
              {maintenance.description && (
                <GridItem fullWidth>
                  <Field label="Примечание">{maintenance.description}</Field>
                </GridItem>
              )}
            </Grid>
          )}
        </StyledPanel>
        <Section>
          <SectionTitle divider>Транспортное средство</SectionTitle>
          <Content>
            <Grid cols={3} gutter="16">
              {maintenance &&
                maintenance.vehicle &&
                maintenance.vehicle.vehicleModel && (
                  <>
                    <GridItem>
                      <Field label="Тип">
                        {vehicleTypes[maintenance.vehicle.vehicleModel.type]}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Марка">
                        {maintenance.vehicle.vehicleModel.brandName}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Модель">
                        {maintenance.vehicle.vehicleModel.name}
                      </Field>
                    </GridItem>
                  </>
                )}
              <GridItem>
                {maintenance && maintenance.vehicle.licensePlate && (
                  <Field label="Государственный регистрационный знак">
                    {applyMaskToValue(
                      maintenance.vehicle.licensePlate,
                      formatLicensePlateMask
                    )}
                  </Field>
                )}
              </GridItem>
            </Grid>
          </Content>
        </Section>
        <Section>
          <SectionTitle divider>Дефектная ведомость</SectionTitle>
          <Content>
            {maintenance && (
              <VehicleIssuesReadOnly
                handlePrint={this.handlePrint}
                maintenanceWorkType={maintenance.maintenanceWorkType}
                vehicleIssues={vehicleIssues}
              />
            )}
          </Content>
        </Section>
        <Section>
          <SectionTitle
            divider
            suffix={
              <>
                {maintenance && (
                  <StyledButtonDiv>
                    <DeclineDetailedDefectiveStatementSelect
                      detailedDefectiveStatement={
                        declinedDetailedDefectiveStatement
                      }
                      onChange={this.onChangeDeclineDetailedDefectiveStatement}
                      maintenanceId={maintenance.id}
                    />
                    {detailedDefectiveStatement &&
                      !isDeclinedDetailedDefectiveStatement &&
                      detailedDefectiveStatement.id !== 0 && (
                        <Dropdown
                          overlay={
                            <Menu onClick={this.handlePrint}>
                              <Menu.Item key={'print'}>
                                Дефектная ведомость
                              </Menu.Item>
                              <Menu.Item key={'printOs3'}>Форма ОС-3</Menu.Item>
                              <Menu.Item key={'printWriteOffAct'}>
                                Акт о списании материально-производственных
                                запасов
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Button type="primary">Печатные формы</Button>
                        </Dropdown>
                      )}
                  </StyledButtonDiv>
                )}
              </>
            }
          >
            Детальная дефектная ведомость
          </SectionTitle>
          <Content>
            {maintenance && (
              <Grid cols={3} gutter="16" style={{ marginBottom: 16 }}>
                <GridItem>
                  <Field label="Период выполнения работ">
                    {`${formatDateTimeToString(
                      detailedDefectiveStatement.startDate,
                      'DD.MM.YYYY'
                    )} - ${formatDateTimeToString(
                      detailedDefectiveStatement.endDate,
                      'DD.MM.YYYY'
                    )}`}
                  </Field>
                </GridItem>
                <GridItem>
                  {showWorkEngineHours(maintenance.vehicle) ? (
                    <Field label="Показания моточасов при окончании работ">
                      {toLocalStringRu(maintenance.workEngineHours)}
                    </Field>
                  ) : (
                    <Field label="Показания одометра при окончании работ">
                      {toLocalStringRu(maintenance.kilometrage)}
                    </Field>
                  )}
                </GridItem>
              </Grid>
            )}
            {maintenance && (
              <>
                <ContentTitle style={{ marginTop: 0 }}>Работы</ContentTitle>
                <OperationsReadOnly
                  detailedDefectiveStatement={detailedDefectiveStatement}
                />
              </>
            )}
            {maintenance && (
              <>
                <ContentTitle>Расходники и запчасти</ContentTitle>
                <PartsAndConsumablesReadOnly
                  orderContractorType={maintenance.orderContractorType}
                  detailedDefectiveStatement={detailedDefectiveStatement}
                />
              </>
            )}
          </Content>
        </Section>
      </>
    );
  }
}

export default MaintenanceCard;
