// @flow
import React from 'react';
import moment from 'moment';
import { RangePicker } from '../ant/DatePicker';
import { formatDateTimeToISOString } from '../../lib/helpers';

type Props = {
  startDate: string | moment,
  endDate: string | moment,
  onChange: Function,
  format?: string,
  size?: string,
};
export default (props: Props) => {
  return (
    <RangePicker
      format={props.format ? props.format : 'DD.MM.YYYY HH:mm'}
      size={props.size}
      // showTime={{
      //   format: 'HH:mm',
      //   defaultValue: [
      //     moment('00:00', 'HH:mm'),
      //     moment('23:59', 'HH:mm')
      //   ]
      // }}
      placeholder={['Начало', 'Конец']}
      value={[
        props.startDate ? moment.utc(props.startDate) : null,
        props.endDate ? moment.utc(props.endDate) : null,
      ]}
      onChange={(value, dateString) => {
        const [startDate, endDate] = value;
        const [startDateString, endDateString] = dateString;
        props.onChange(
          'startDate',
          formatDateTimeToISOString(startDate, startDateString)
        );
        props.onChange(
          'endDate',
          formatDateTimeToISOString(
            endDate,
            endDate.endOf('day').format('DD.MM.YYYY HH:mm').toString()
          )
        );
      }}
    />
  );
};
