import setting from './setting';
import crew from './crew';
import maintenanceMatrix from './maintenanceMatrix';
import fuelPrice from './fuelPrice';
import vehicleTariff from './vehicleTariff';
import maintenanceOperationPrice from './maintenanceOperationPrice';
import maintenanceWorkType from './maintenanceWorkType';
import compareExpenseDirection from './compareExpenseDirection';
import expenseDirection from './expenseDirection';
import stockPart from './stockPart';
import monitoringPrice from './monitoringPrice';
import vehicleIssueGroup from './vehicleIssueGroup';
import osagoMultiplier from './osagoMultiplier';
import maintenanceOperationGroups from './maintenanceOperationGroups';
import maintenanceOperationsByBrand from './maintenanceOperationsByBrand';
import maintenanceOperations from './maintenanceOperations';
import washingPriceList from './washingPriceList';
import fuelCards from './fuelCards';
import brands from './brands';
import users from './users';
import roles from './roles';
import orgUnits from './orgUnits';
import employees from './employees';
import locations from './locations';
import drivers from './drivers';
import fuelsAndOils from './fuelsAndOils';
import fuelMultipliers from './fuelMultipliers';
import companies from './companies';
import contractors from './contractors';
import budgetVersion from './budgetVersion';
import okei from './okei';
import spareParts from './spareParts';
import federalHighwayFare from './federalHighwayFare';
import gpsSubscriptionServiceCost from './gpsSubscriptionServiceCost';
import measuringDeviceCertificationCost from './measuringDeviceCertificationCost';
import inspectionGibddCost from './inspectionGibddCost';
import inspectionGtnCost from './inspectionGtnCost';
import licensePlateChangeCost from './licensePlateChangeCost';
import stock from './stock';
import expertiseServiceGpmCost from './expertiseServiceGpmCost';
import washingFrequency from './washingFrequency';
import tripInspectionCost from './tripInspectionCost';
import tripInspectionFrequency from './tripInspectionFrequency';
import overhaulTypes from './overhaulTypes';
import workingHourContractPrice from './workingHourContractPrice';
import currentMaintenanceCost from './currentMaintenanceCost';
import maintenanceCostAllocationTr from './maintenanceCostAllocationTr';
import maintenanceCostAllocation from './maintenanceCostAllocation';
import vehicleTaxRate from './vehicleTaxRate';
import vehicleTaxMap from './vehicleTaxMap';
import budgetsForAnalysis from './budgetsForAnalysis';
import workingHourSelfPrice from './workingHourSelfPrice';
import passTariff from './passTariff';
import journals from './journals';
import mdm from './mdm';
import jobTitles from './jobTitles';
import businessCalendar from './businessCalendar';
import theBusinessCalendarForBudget from './theBusinessCalendarForBudget';
import stou from './stou';
import washingVehicleTypes from './washingVehicleTypes';
import washingVehicleTypeMap from './washingVehicleTypeMap';
import subscriptions from './subscriptions';
import pdd from './pdd';
import smallScaleMechanization from './smallScaleMechanization';

export default [
    ...subscriptions,
    ...businessCalendar,
    ...smallScaleMechanization,
    ...pdd,
    ...washingVehicleTypeMap,
    ...washingVehicleTypes,
    ...stou,
    ...theBusinessCalendarForBudget,
    ...jobTitles,
    ...mdm,
    ...journals,
    ...passTariff,
    ...workingHourSelfPrice,
    ...budgetsForAnalysis,
    ...vehicleTaxMap,
    ...vehicleTaxRate,
    ...maintenanceCostAllocation,
    ...maintenanceCostAllocationTr,
    ...currentMaintenanceCost,
    ...workingHourContractPrice,
    ...overhaulTypes,
    ...tripInspectionFrequency,
    ...tripInspectionCost,
    ...washingFrequency,
    ...expertiseServiceGpmCost,
    ...stock,
    ...licensePlateChangeCost,
    ...inspectionGtnCost,
    ...inspectionGibddCost,
    ...measuringDeviceCertificationCost,
    ...gpsSubscriptionServiceCost,
    ...federalHighwayFare,
    ...spareParts,
    ...okei,
    ...budgetVersion,
    ...contractors,
    ...companies,
    ...fuelMultipliers,
    ...fuelsAndOils,
    ...drivers,
    ...locations,
    ...employees,
    ...orgUnits,
    ...roles,
    ...users,
    ...brands,
    ...fuelCards,
    ...washingPriceList,
    ...maintenanceOperations,
    ...maintenanceOperationsByBrand,
    ...maintenanceOperationGroups,
    ...osagoMultiplier,
    ...vehicleIssueGroup,
    ...monitoringPrice,
    ...stockPart,
    ...expenseDirection,
    ...compareExpenseDirection,
    ...maintenanceWorkType,
    ...maintenanceOperationPrice,
    ...vehicleTariff,
    ...maintenanceMatrix,
    ...fuelPrice,
    ...setting,
    ...crew,
];