import React, { useState } from 'react';

import Select from 'antd/lib/select';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import { DatePicker } from '../../../components/ant/DatePicker';
import { FormikProps } from 'formik';
import moment from 'moment';

import { applyMaskToValue } from '../../../lib/helpers';
import { BioTreatmentFacilityFact } from '../../../lib/types';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import { Form } from '../../../components';
import { Grid } from '../../../components/layout';
import { GridItem } from '../../../components/layout/Grid';

const { Option } = Select;

type Props = {
  visible: boolean,
  licensePlates: string[],
  onCancel: Function,
  onSubmit: Function,
  bioTreatmentFacilityFact: ?BioTreatmentFacilityFact,
};
export default (props: Props) => {
  const {
    visible,
    onCancel,
    onSubmit,
    licensePlates,
    bioTreatmentFacilityFact = {},
  } = props;
  return (
    <Modal
      destroyOnClose
      width={800}
      title="Форма внесения факта"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" form="modal-form" htmlType="submit">
          Добавить
        </Button>,
      ]}
    >
      <Form initialValues={bioTreatmentFacilityFact} onSubmit={onSubmit}>
        {(FormField, formikProps: FormikProps) => {
          const { setFieldValue, handleSubmit, values } = formikProps;
          return (
            <form onSubmit={handleSubmit} id="modal-form">
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Дата" fast name="date" required>
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        showTime={{ format: 'HH:mm' }}
                        value={value ? moment.utc(value) : value}
                        format="DD MMMM YYYY HH:mm"
                        onChange={(value: string) => {
                          console.log(value);
                          setFieldValue(
                            name,
                            moment(value).toISOString()
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField
                    label="Гос.номер"
                    fast
                    name="licensePlate"
                    required
                  >
                    {({ name, value }) => (
                      <Select
                        onChange={(licensePlate) => {
                          setFieldValue(name, licensePlate);
                        }}
                        allowClear
                        defaultValue={values.licensePlate}
                      >
                        {licensePlates.map((licensePlate) => (
                          <Option value={licensePlate}>
                            {applyMaskToValue(
                              licensePlate,
                              formatLicensePlateMask
                            )}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
