//@flow
import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import notification from 'antd/lib/notification';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';

import { getListInitialState } from '../../../lib/helpers';
import { bioTreatmentFacilityTalonApi } from '../../../lib/api';
import { bioTreatmentFacilityFactStatusEnum, bioTreatmentFacilityTalonStatusEnum } from '../../../lib/enum';
import type { BioTreatmentFacilityFact } from '../../../lib/types';

import BioTreatmentFacilityTalonFilterTable from '../../BioTreatmentFacilityTalon/components/BioTreatmentFacilityTalonFilterTable';
import { Form, Card, Selects } from '../../../components';
import { notificationLoading } from '../../../components/Notifications';
import { Grid } from '../../../components/layout';
import { GridItem } from '../../../components/layout/Grid';

const { Field } = Card;

type Props = {
  visible: boolean,
  onCancel: Function,
  onSubmit: Function,
  onCancelTalon: Function,
  bioTreatmentFacilityFact: ?BioTreatmentFacilityFact,
};
export default (props: Props) => {
  const { visible, onCancel } = props;
  const [state, setState] = useState({
    ...getListInitialState(),
    selected: null,
    bioTreatmentFacilityContractId: null,
    pageSize: 10,
    filter: {},
  });

  const onSubmit = () => {
    try {
      if (state.selected === null) {
        throw new Error('Талон не выбран');
      }
      props.onSubmit({
        ...props.bioTreatmentFacilityFact,
        status: bioTreatmentFacilityFactStatusEnum.redeemedOperator,
        bioTreatmentFacilityTalonId: state.selected,
      }, state.bioTreatmentFacilityContractId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  const onCancelTalon = () => {
    props.onCancelTalon(props.bioTreatmentFacilityFact);
  }

  const fetch = async (page: number) => {
    const { filter, pageSize } = state;
    setState((oldState) => ({ ...oldState, loading: true }));
    try {
      notificationLoading({
        message: 'Запрос данных...',
        key: 'savingTalon',
      });
      const data = await bioTreatmentFacilityTalonApi.list({
        ...filter,
        checkNumberCoupon: true,
        status: bioTreatmentFacilityTalonStatusEnum.approved,
        pageSize,
        page,
      });
      setState((oldState) => ({ ...oldState, ...data }));
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('savingTalon');
      setState((oldState) => ({ ...oldState, loading: false, page }));
    }
  };

  const applyFilter = (filter) => {
    setState((oldState) => ({ ...oldState, filter, page: 1 }));
  };

  const cleanFilter = () => {
    setState((oldState) => ({ ...oldState, filter: {}, page: 1 }));
  };

  const onChangeCheckbox = (id, event, talon) => {
    setState((oldState) => ({
      ...oldState,
      selected: event.target.checked ? id : null,
      bioTreatmentFacilityContractId: event.target.checked ? talon?.bioTreatmentFacilityVolume?.bioTreatmentFacilityContractId ?? null : null
    }));
  };

  useEffect(() => {
    fetch(1);
  }, []);

  useEffect(() => {
    console.log(props?.bioTreatmentFacilityFact);
    setState((oldState) => ({
      ...oldState,
      filter: {
        ...oldState.filter,
        licensePlate: [bioTreatmentFacilityFactStatusEnum.notFound, bioTreatmentFacilityFactStatusEnum.canceled].includes(props?.bioTreatmentFacilityFact?.status) ?
          props?.bioTreatmentFacilityFact.licensePlate : undefined,
        'bioTreatmentFacilityVolume.bioTreatmentFacilityContract.companyId':
          props?.bioTreatmentFacilityFact?.bioTreatmentFacilityTalon?.bioTreatmentFacilityVolume?.bioTreatmentFacilityContract?.companyId ?? null
      },
      selected:
        props?.bioTreatmentFacilityFact?.bioTreatmentFacilityTalonId ?? null,
    }));
  }, [props?.bioTreatmentFacilityFact]);

  useEffect(() => {
    fetch(state.page);
  }, [state.filter]);

  return (
    <Modal
      destroyOnClose
      width={1200}
      title="Форма внесения талона"
      visible={visible}
      onCancel={onCancel}
      footer={[
        props?.bioTreatmentFacilityFact?.status && props?.bioTreatmentFacilityFact?.status !== bioTreatmentFacilityFactStatusEnum.canceled ? (
          <Button key="onCancelTalon" onClick={onCancelTalon}>
            Отменить заезд
          </Button>
        ) : null,
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmit}>
          Сохранить
        </Button>,
      ]}
    >
      <BioTreatmentFacilityTalonFilterTable
        onChangeCheckbox={onChangeCheckbox}
        filter={state.filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
        data={state.data}
        fetch={fetch}
        loading={state.loading}
        page={state.page}
        pageSize={state.pageSize}
        totalCount={state.totalCount}
        location={{}}
        selected={state.selected}
      />
    </Modal>
  );
};
