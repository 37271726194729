// @flow

import { ExclamationCircleTwoTone } from '@ant-design/icons';
import React, { Component } from 'react';
import qs from 'query-string';
import { Link } from '@reach/router';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import Popover from 'antd/lib/popover';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type {
  Maintenance,
  MaintenanceWorkType,
  ListState,
  UserAccess,
  RepairPlan,
  OrderContractorType,
} from './../../lib/types';
import {
  maintenanceStatusesTypes,
  repairPlanTypes,
  orderContractorTypes,
  maintenanceStatusesEnum,
} from './../../lib/enum';
import { applyMaskToValue, formatDateTimeToString } from './../../lib/helpers';
import { maintenanceApi } from './../../lib/api';
import { getListInitialState } from './../../lib/helpers';

import {
  Popconfirm,
  Icon,
  Operations,
  Table,
  ButtonsRow,
} from './../../components/ui';
import { TableHeader } from '../../components/ui/Table';
import { Section } from './../../components/layout';
import Header from '../../components/layout/Header';
import { notificationLoading } from './../../components/Notifications';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';

import { withUserAccess } from './../withUserAccess';

import type { MaintenanceFilterParams } from './components/Filter';
import { Filter } from './components';
import { addAccessRight, editAccessRight } from './accessRight';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<Maintenance> & {
  filter: MaintenanceFilterParams,
};
const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

const NumContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Number = styled.div`
  flex: 1;
`;

export const COLUMNS = [
  {
    title: 'Номер',
    dataIndex: 'id',
    render: (id: number, record: Maintenance) => {
      return (
        <NumContainer>
          <Number>{id}</Number>
          {record.isOverdue && (
            <Popover
              content={
                <div>
                  <div>
                    Регламентный пробег: {record.plannedKilometrage ?? '-'}
                  </div>
                  <div>
                    Пробег при прохождении ТО: {record.kilometrage ?? '-'}
                  </div>
                </div>
              }
              placement="right"
            >
              <ExclamationCircleTwoTone
                twoToneColor="red"
                style={{
                  fontSize: '13px',
                  position: 'relative',
                  marginLeft: '10px',
                  top: '4px',
                }}
              />
            </Popover>
          )}
        </NumContainer>
      );
    },
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => maintenanceStatusesTypes[status],
  },
  {
    title: (
      <TableHeader width="110px">Планируемая дата проведения работ</TableHeader>
    ),
    dataIndex: 'maintenancePlannedDate',
    render: (workPlannedDate: string) =>
      `${formatDateTimeToString(workPlannedDate, 'DD.MM.YYYY')}`,
  },
  {
    title: 'Тип',
    dataIndex: 'maintenanceWorkType',
    key: 'maintenanceWorkType',
    render: (maintenanceWorkType: MaintenanceWorkType) =>
      maintenanceWorkType.name,
  },
  {
    title: 'График работ',
    dataIndex: 'repairPlan',
    key: 'repairPlan',
    render: (type: RepairPlan) => repairPlanTypes[type],
  },
  {
    title: 'Способ выполнения',
    dataIndex: 'orderContractorType',
    key: 'orderContractorType',
    render: (type: OrderContractorType) => orderContractorTypes[type],
  },
  {
    title: 'Направление расходов',
    dataIndex: 'expenseDirection.name',
    key: 'expenseDirection',
  },
  {
    title: 'Гос. номер',
    dataIndex: 'vehicle.licensePlate',
    render: (licensePlate: ?string) =>
      licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask),
  },
  {
    title: 'Марка',
    dataIndex: 'vehicle.vehicleModel.brandName',
  },
  {
    title: 'Модель',
    dataIndex: 'vehicle.vehicleModel.name',
  },
  {
    title: 'Дата создания',
    dataIndex: 'created',
    render: (dateCreated: string) =>
      `${formatDateTimeToString(dateCreated, 'DD.MM.YYYY')}`,
  },
];

class MaintenanceList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  columns = [...COLUMNS];

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.fetchMaintenances(page)
    );
    if (this.canAdd()) {
      this.columns.push({
        title: ' ',
        // eslint-disable-next-line no-unused-vars
        render: (text: string, record: Maintenance): any =>
          record.status !== maintenanceStatusesEnum.approved && (
            <Operations>
              <Popconfirm
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={async () => await this.deleteMaintenance(record.id)}
              >
                <StyledIcon type="x" />
              </Popconfirm>
            </Operations>
          ),
      });
    }
  }

  fetchMaintenances = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await maintenanceApi.fetchMaintenances({
      ...filter,
      page,
    });
    this.setState({ ...data, loading: false, page });
  };

  async deleteMaintenance(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await maintenanceApi.deleteMaintenance(id);
      await this.fetchMaintenances(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  applyFilter = (filter: MaintenanceFilterParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () =>
      this.fetchMaintenances(page)
    );
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchMaintenances(page)
    );
  };

  handlePrint = async () => {
    const { filter } = this.state;
    try {
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print',
      });
      await maintenanceApi.print(filter, `График проведения текущего то.xlsx`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  handleRowClick = (id: number, maintenance: Maintenance) =>
    this.canEdit() && !this.isApprove(maintenance)
      ? navigate(`/maintenances/journal/${id}`)
      : navigate(`/maintenances/journal/read/${id}`);

  isApprove = (maintenance: Maintenance) => {
    console.log(maintenance, maintenanceStatusesEnum);
    return maintenance?.status === maintenanceStatusesEnum.approved;
  };

  canAdd = () =>
    this.props.userAccess.some((access) => addAccessRight.includes(access));

  canEdit = () =>
    this.props.userAccess.some((access) => editAccessRight.includes(access));

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Ремонты и ТО</h1>}
          right={
            <ButtonsRow>
              <Button onClick={this.handlePrint}>
                Печать отчёта по ремонтам и ТО
              </Button>
              {canAdd && (
                <Link to={`/maintenances/journal/new`}>
                  <Button type="primary" data-cy="addMaintenance">
                    Создать
                  </Button>
                </Link>
              )}
            </ButtonsRow>
          }
        />
        <Section>
          <Filter
            filter={filter}
            cleanFilter={this.cleanFilter}
            applyFilter={this.applyFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id, record),
            })}
            rowKey="id"
            fetch={this.fetchMaintenances}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(MaintenanceList);
